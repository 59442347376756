import React from 'react';
import styles from './app-bar.module.scss';
import { getLoggedUser, openChangePasswordDialog } from '../../../store/authSlice';
import VpnKey from '../../../assets/images/icons/vpn_key.svg';
import Logout from '../../../assets/images/icons/logout.svg';
import InfoIcon from '../../../assets/images/icons/info.svg';
import { cleanLocalStorageAndLogoff, setAboutSectionIsOpened } from '../../../store/appSlice';
import { useAppDispatch } from '../../../hooks';
import { useSelector } from 'react-redux';
import { DropdownItem, Dropdowns } from '../../utils/dropdowns';
import { Avatar } from '../../utils/avatar';
import Logo from '../../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

export const AppBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const loggedUser = useSelector(getLoggedUser)!;

    const changePasswordItem: DropdownItem = {
        icon: VpnKey,
        onClick: () => dispatch(openChangePasswordDialog()),
        label: 'Edit Password',
    };

    const about: DropdownItem = {
        icon: InfoIcon,
        onClick: () => dispatch(setAboutSectionIsOpened(true)),
        label: 'About'
    };

    const logoffItem: DropdownItem = {
        icon: Logout,
        onClick: () => dispatch(cleanLocalStorageAndLogoff()),
        label: 'Exit',
    };
    return (
        <div className={styles.appBar}>
            <div onClick={() => navigate('/')} className={styles.logo}>
                <img src={Logo} />
            </div>

            <Dropdowns options={[changePasswordItem, about, logoffItem]}>
                <Avatar primary={true} user={loggedUser} />
            </Dropdowns>
        </div>
    );
};
