import React, { FunctionComponent } from 'react';
import { Dialog } from '../dialog';
import styles from './confirm.module.scss';
import { VoidCallback } from '../../../utils';

interface Props {
    isOpen: boolean;
    title: string;
    subtitle?: string;
    text: string;
    onConfirmed: VoidCallback;
    onClose: VoidCallback;
    textConfirm?: string;
    actionColor?: 'primary' | 'error';
}

export const Confirm: FunctionComponent<Props> = props => {
    return (
        <Dialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.title}
            subtitle={props.subtitle}
            action={props.textConfirm || 'Confirm'}
            onAction={props.onConfirmed}
        >
            <div className={styles.bodyConfirm}>{props.text}</div>
        </Dialog>
    );
};
