import { FunctionComponent, useCallback } from 'react';
import styles from './hidden-logoff-button.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { cleanLocalStorageAndLogoff } from '../../../store/appSlice';
import { getLoggedUser } from '../../../store/authSlice';
import { Role } from '../../../types/BackofficeUser';
import LogoffIcon from '../../../assets/images/icons/logout.svg';

export const HiddenLogoffButton: FunctionComponent = () => {
    const loggedUser = useAppSelector(getLoggedUser);
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(cleanLocalStorageAndLogoff());
    }, [dispatch]);

    if (loggedUser?.role !== Role.kitchen)
        return null;

    return (
        <div className={styles.hiddenLogoffButton} onClick={handleClick}>
            <img src={LogoffIcon} alt="Sair" />
        </div>
    );
};
