export enum CouponTypeEnum {
    percent = 'percent',
    absolute = 'absolute',
    freeShipment = 'freeShipment',
}

export enum CouponUseTypeEnum {
    oneUse = 'oneUse',
    reusable = 'reusable',
    firstPurchase = 'firstPurchase'
}

export interface BasicCouponDto {
    clientId?: string;
    limitQuantity?: number;
    startValidPeriod?: Date;
    finalValidPeriod?: Date;
}

export interface CreateCouponDto extends BasicCouponDto {
    key: string;
    type: CouponTypeEnum;
    discountValue: number;
    useType: CouponUseTypeEnum;
    minPurchaseValue?: number;
}

export interface DiscountCoupon extends CreateCouponDto {
    id: string;
    qtyUsed: number;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
}

export interface UpdateCouponDto extends BasicCouponDto {
    id: string;
}
