import { AnnotationEnum, CategoryEnum } from '../types/Product';
import { DeliveryMethodEnum, Order, PaymentMethodEnum, StatusEnum, StatusPaymentEnum, ToGoOrder } from '../types/Order';
import { CouponTypeEnum, CouponUseTypeEnum, DiscountCoupon } from '../types/DIscountCoupon';
import { MessageProvider, WeekDayEnum } from '../types/Settings';
import { getEnumKeys } from './index';

export const allMessageProviders: Record<MessageProvider, string> = {
    [MessageProvider.telegram]: 'Telegram',
    [MessageProvider.smsTwilio]: 'SMS Twilio',
    [MessageProvider.whatsappTwilio]: 'Whatsapp Twilio',
    [MessageProvider.comtele]: 'SMS Comtele',
    [MessageProvider.zenvia]: 'SMS Zenvia'
};

export const allWeekDays: Record<WeekDayEnum, string> = {
    [WeekDayEnum.sunday]: 'Domingo',
    [WeekDayEnum.monday]: 'Segunda',
    [WeekDayEnum.tuesday]: 'Terça',
    [WeekDayEnum.wednesday]: 'Quarta',
    [WeekDayEnum.thursday]: 'Quinta',
    [WeekDayEnum.friday]: 'Sexta',
    [WeekDayEnum.saturday]: 'Sábado'
};

export const allAnnotations: Record<AnnotationEnum, string> = {
    [AnnotationEnum.smoked]: 'Smoked',
    [AnnotationEnum.spicy]: 'Apimentado',
    [AnnotationEnum.lactose]: 'Lactose'
};
export const formatAnnotation = (annotation?: AnnotationEnum): string => {
    if (!annotation)
        return '';

    return allAnnotations[annotation];
};

export const allCategories: Record<CategoryEnum, string> = {
    [CategoryEnum.entries]: 'Entradas',
    [CategoryEnum.sideDishes]: 'Acompanhamentos',
    [CategoryEnum.smokedMeets]: 'Defumados',
    [CategoryEnum.grilled]: 'Grelhados',
    [CategoryEnum.burgers]: 'Hamburguers',
    [CategoryEnum.sandwiches]: 'Sanduíches',
    [CategoryEnum.sauces]: 'Molhos',
    [CategoryEnum.salads]: 'Saladas',
    [CategoryEnum.deserts]: 'Sobremesas',
    [CategoryEnum.beverages]: 'Bebidas'
};

export const formatCategory = (category: CategoryEnum): string => allCategories[category];

export const allCouponTypes: Record<CouponTypeEnum, string> = {
    [CouponTypeEnum.percent]: 'Porcentagem',
    [CouponTypeEnum.absolute]: 'Valor absoluto',
    [CouponTypeEnum.freeShipment]: 'Frete grátis'
};

export const formatCouponType = (type: CouponTypeEnum): string => allCouponTypes[type];

export const formatCouponValue = (obj: DiscountCoupon): string => {
    return {
        [CouponTypeEnum.percent]: `${obj.discountValue}%`,
        [CouponTypeEnum.absolute]: formatPriceAmount(obj.discountValue),
        [CouponTypeEnum.freeShipment]: 'Frete grátis'
    }[obj.type];
};

export const allCouponUseTypes: Record<CouponUseTypeEnum, string> = {
    [CouponUseTypeEnum.oneUse]: 'Uso único',
    [CouponUseTypeEnum.reusable]: 'Reutilizável',
    [CouponUseTypeEnum.firstPurchase]: 'Primeira compra'
};

export const formatCouponUseType = (useType: CouponUseTypeEnum): string => allCouponUseTypes[useType];

export const phoneFormatter = (phoneNumber: string): string => {
    const num = phoneNumber.replace(/^55/, '');
    return `(${num.slice(0, 2)}) ${num.slice(2, 3)} ${num.slice(3, 7)}-${num.slice(-4)}`;
};
export const getTotalValue = (order: Order): string => {
    const subTotal = order.products
        .reduce((a, b) => a + (b.quantity * b.product.price), 0);

    const shippingPrice = (order as ToGoOrder).area?.deliveryFee || 0;
    const couponValue = order.discountCoupon?.discountValue || 0;
    const result = subTotal + shippingPrice - couponValue;

    return formatPriceAmount(result);
};

export const formatPriceAmount = (n: number | undefined): string => {
    if (!n)
        return '';

    const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
    return format.format(n);
};

const formatPaymentStatus = (status: StatusPaymentEnum): string => {
    return {
        [StatusPaymentEnum.paid]: 'Pago',
        [StatusPaymentEnum.pending]: 'Pendente'
    }[status];
};
export const formatPaymentInfo = (obj: Order) => {
    return {
        [PaymentMethodEnum.pix]: `${formatPaymentStatus(obj.statusPayment)} via pix`,
        [PaymentMethodEnum.creditCard]: `${formatPaymentStatus(obj.statusPayment)} via cartão de crédito`,
        [PaymentMethodEnum.cash]: 'Pagamento na entrega'
    }[obj.paymentMethod];
};

export const formatStatus = (obj: Order): string => {
    return {
        [StatusEnum.pending]: 'Pendente',
        [StatusEnum.inPreparation]: 'Em preparo',
        [StatusEnum.readyToDeliver]: {
            [DeliveryMethodEnum.delivery]: 'Pronto para retirada',
            [DeliveryMethodEnum.pickup]: 'Pronto para entrega'
        }[obj.deliveryMethod],
        [StatusEnum.inTransit]: 'Em trânsito',
        [StatusEnum.delivered]: 'Entregue'
    }[obj.status];
};

export const formatDeliveryMethod = (obj: Order): string => {
    return {
        [DeliveryMethodEnum.pickup]: 'Retirada no balcão',
        [DeliveryMethodEnum.delivery]: 'Entrega no endereço'
    }[obj.deliveryMethod];
};

export const formatDeliveryInfo = (obj: Order): string => {
    if (obj.deliveryMethod === DeliveryMethodEnum.pickup)
        return 'Retirada no balcão';

    return `${obj.address.streetLine}, ${obj.address.number} ${obj.address.complement || ''}`;
};

export const sortedCategories = getEnumKeys(allCategories);
