import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FieldMasked } from '../field-masked';

interface Props {
    label: string;
    value: Date | undefined;
    onChange: (val: Date | undefined) => void;
}

const dateFormatter = (dt: string | null | undefined): string => {
    if (!dt) return '';

    const slice = (start: number, end: number): string => dt.slice(start, end);
    const firstBloc = slice(0, 2);
    const secondBloc = slice(2, 4);
    const thirdBloc = slice(4, 8);

    if (!!thirdBloc.length)
        return `${firstBloc}/${secondBloc}/${thirdBloc}`;

    if (!!secondBloc.length)
        return `${firstBloc}/${secondBloc}`;

    return firstBloc;
};

const getDate = (dt: string): Date | null => {
    const [day, month, year] = dt.split('/');
    if (!day || !month || !year || year.length !== 4)
        return null;

    return new Date(`${year}-${month}-${day}`);
};

const validDate = (dt: string): boolean => {
    const date = getDate(dt);
    if (!date)
        return false;

    return date.toString() !== 'Invalid Date';
};

export const FieldDate: FunctionComponent<Props> = props => {
    const [val, setVal] = useState('');

    const handleChange = useCallback((value: string) => {
        const validValue = getDate(dateFormatter(value)) || undefined;
        props.onChange(validValue);
    }, [props]);

    useEffect(() => {
        if (!props.value)
            return;

        const [year, month, day] = props.value.toISOString().slice(0, 10).split('-');
        setVal(day + month + year);
    }, [setVal, props]);

    return (
        <FieldMasked
            value={val}
            onChange={handleChange}
            label={props.label}
            placeHolder="dd/mm/yyyy"
            formatter={dateFormatter}
            validator={validDate}
        />
    );
};
