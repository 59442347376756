import { FunctionComponent, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { BasePagination, basicPagination } from '../../utils/pagination';
import { useNavigate } from 'react-router-dom';
import { downloadOrdersList, getOrders, getTotalOrders } from '../../store/orderSlice';
import { Order } from '../../types/Order';
import { formatDeliveryInfo, formatPaymentInfo, formatStatus, getTotalValue } from '../../utils/formatters';
import styles from './orders.module.scss';
import { HeaderApp } from '../utils/header';
import { Card } from '../utils/card';
import { Paginator } from '../utils/paginator';
import { IconButton } from '../utils/icon-button';

export const Orders: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const orders = useAppSelector(getOrders(pagination));
    const total = useAppSelector(getTotalOrders);

    const downloadReport = useCallback(() => {
        dispatch(downloadOrdersList());
    }, [dispatch]);

    const openOrder = useCallback((data: Order) => () => {
        navigate(`/orders/${data.id}`);
    }, [navigate]);

    const renderTable = () => {
        if (!total)
            return <div>Nenhum pedido encontrado</div>;

        return (
            <div className={styles.tableOrders}>
                <table>
                    <thead>
                    <tr>
                        <th>Qtd Itens</th>
                        <th>Status</th>
                        <th>Pagamento</th>
                        <th>Entrega</th>
                        <th>Valor</th>
                        <th>Pedido</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map(x => (
                        <tr key={x.id} onClick={openOrder(x)}>
                            <td data-title="Qtd Itens">{x.products.reduce((agg, b) => b.quantity + agg, 0)}</td>
                            <td data-title="Status">{formatStatus(x)}</td>
                            <td data-title="Pagamento">{formatPaymentInfo(x)}</td>
                            <td data-title="Entrega">{formatDeliveryInfo(x)}</td>
                            <td data-title="Valor">{getTotalValue(x)}</td>
                            <td data-title="Data">{x.createdAt.toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <Card className={styles.orders}>
            <HeaderApp title="Pedidos">
                <IconButton onClick={downloadReport} icon="download" />
            </HeaderApp>
            {renderTable()}
            <Paginator pagination={pagination} totals={total} setPage={setPagination} />
        </Card>
    );
};
