import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { Api } from '../services/Api';
import { showAlert } from './notificationSlice';
import { ChangePasswordDto } from '../types/ChangePasswordDto';
import { Websocket } from '../services/Websocket';
import { delay, userFromToken } from '../utils';
import { BackofficeUser, LoginRequest, SendTwoFactorCode } from '../types/BackofficeUser';
import BackofficeUserService from '../services/BackofficeUserService';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';

export const TOKEN_KEY = 'token';
export const PASSWORD_MUST_BE_CHANGED_KEY = 'password-must-be-changed';

export enum LoginStateEnum {
    willDoBasicAuth = 'not-started',
    willDoTwoFactorAuth = 'started'
}

interface AuthState {
    phoneNumber: string | null,
    password: string | null,
    loggedUser: BackofficeUser | null,
    loginState: LoginStateEnum,
    isLoginLoading: boolean,
    tokenExpiresAt: number | null;
    passwordMustBeChanged: boolean;
    changePasswordDialogIsOpened: boolean;
}

const initialState: AuthState = {
    phoneNumber: null,
    password: null,
    loggedUser: null,
    loginState: LoginStateEnum.willDoBasicAuth,
    isLoginLoading: false,
    tokenExpiresAt: null,
    passwordMustBeChanged: false,
    changePasswordDialogIsOpened: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateLoggedUser: (state, action: PayloadAction<BackofficeUser | null>) => {
            state.loggedUser = action.payload;
        },
        updateStateLogin: (state, action: PayloadAction<LoginStateEnum>) => {
            state.loginState = action.payload;
        },
        updateTokenExpiration: (state, action: PayloadAction<number | null>) => {
            state.tokenExpiresAt = action.payload;
        },
        setPasswordMustBeChanged: (state, action: PayloadAction<boolean>) => {
            state.passwordMustBeChanged = action.payload;
        },
        setIsLoginLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoginLoading = action.payload;
        },
        openChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = true;
        },
        closeChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = false;
        },
        setLoginInfo: (state, action: PayloadAction<SendTwoFactorCode>) => {
            state.phoneNumber = action.payload.phoneNumber;
            state.password = action.payload.password;
        },
        cleanLoginInfo: state => {
            state.phoneNumber = null;
            state.password = null;
        }
    }
});

const {
    updateLoggedUser,
    updateStateLogin,
    updateTokenExpiration,
    setPasswordMustBeChanged,
    setIsLoginLoading,
} = authSlice.actions;

export const {
    closeChangePasswordDialog,
    openChangePasswordDialog,
    cleanLoginInfo,
    setLoginInfo
} = authSlice.actions;

export const renewToken = (): AppThunk<Promise<void>> => () =>
    BackofficeUserService.renewToken();

export const create2fa = (obj: SendTwoFactorCode): AppThunk => dispatch => {
    dispatch(setIsLoginLoading(true));

    BackofficeUserService.create2FA(obj)
        .then(() => {
            dispatch(updateStateLogin(LoginStateEnum.willDoTwoFactorAuth));
            dispatch(setLoginInfo(obj));
            dispatch(setIsLoginLoading(false));
        })
        .catch(err => {
            dispatch(setIsLoginLoading(false));
        });
};

export const login = (twoFactorCode: string): AppThunk => (dispatch, getState) => {
    const { phoneNumber, password } = getState().auth;

    dispatch(setIsLoginLoading(true));

    const obj: LoginRequest = {
        phoneNumber: phoneNumber!,
        password: password!,
        twoFactorCode
    };
    BackofficeUserService.login(obj)
        .then(({ token, shouldChangePassword }) => {
            dispatch(updateToken(token, shouldChangePassword));
            dispatch(cleanLoginInfo());
            dispatch(setIsLoginLoading(false));
        })
        .catch(err => {
            dispatch(setIsLoginLoading(false));
        });
};

let timeout: NodeJS.Timeout;
export const updateToken = (token: string, passwordMustBeChanged: boolean): AppThunk => async dispatch => {
    saveOnLocalStorage(TOKEN_KEY, token);
    saveOnLocalStorage(PASSWORD_MUST_BE_CHANGED_KEY, String(passwordMustBeChanged));

    Api.setToken(token);
    Websocket.updateToken(token);

    const user = userFromToken(token)!;

    const expiration = (user as any).exp;
    if (expiration <= Date.now()) {
        dispatch(logoff());
        return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(logoff()), expiration - Date.now());

    dispatch(updateTokenExpiration((user as any).exp));
    dispatch(updateLoggedUser(user));
    dispatch(setPasswordMustBeChanged(passwordMustBeChanged));

    await Websocket.openWebsocketConnection();
};

export const updateOfflineToken = (token: string, passwordMustBeChanged: boolean): AppThunk => dispatch => {
    const user = userFromToken(token)!;
    dispatch(updateLoggedUser(user));
    dispatch(updateTokenExpiration(null));
};

export const loginFromStorage = (): AppThunk => (dispatch, getState) => {
    const token = retrieveFromLocalStorage<string>(TOKEN_KEY);
    const passwordMustBeChanged = retrieveFromLocalStorage<string>(PASSWORD_MUST_BE_CHANGED_KEY) === 'true';

    if (!token)
        return;

    let hasConnection = getState().app.hasConnection;
    const waitForConnection = async (): Promise<void> => {
        if (hasConnection) {
            return;
        }

        await delay(10 * 1000);
        hasConnection = getState().app.hasConnection;
        return waitForConnection();
    };

    if (!hasConnection)
        dispatch(updateOfflineToken(token, passwordMustBeChanged));

    waitForConnection()
        .then(() => dispatch(updateToken(token, passwordMustBeChanged)));
};

export const cleanAuthStorageAndLogoff = (): AppThunk => dispatch => {
    removeOnLocalStorage(TOKEN_KEY);
    removeOnLocalStorage(PASSWORD_MUST_BE_CHANGED_KEY);
    dispatch(logoff());
};

export const logoff = (): AppThunk => dispatch => {
    Api.setToken('');
    Websocket.updateToken('');

    dispatch(updateTokenExpiration(null));
    dispatch(updateLoggedUser(null));
    dispatch(setPasswordMustBeChanged(false));
    dispatch(updateStateLogin(LoginStateEnum.willDoBasicAuth));
};

export const changePassword = (changePasswordForm: ChangePasswordDto): AppThunk => dispatch =>
    BackofficeUserService.changePassword(changePasswordForm)
        .then(() => {
            dispatch(setPasswordMustBeChanged(false));
            dispatch(showAlert('Password changed!'));
        });

export const getBasicLoginInfo = (state: RootState): SendTwoFactorCode => {
    return {
        phoneNumber: state.auth.phoneNumber!,
        password: state.auth.password!
    };
}
export const getLoggedUser = (state: RootState): BackofficeUser | null => state.auth.loggedUser;
export const getLoginIsStarted = (state: RootState): LoginStateEnum => state.auth.loginState;
export const getTokenExpiresAt = (state: RootState): number | null => state.auth.tokenExpiresAt;
export const getPasswordMustBeChanged = (state: RootState): boolean => state.auth.passwordMustBeChanged;
export const getChangePasswordIsOpened = (state: RootState): boolean => state.auth.changePasswordDialogIsOpened;
export const getIsLoginLoading = (state: RootState): boolean => state.auth.isLoginLoading;

export default authSlice.reducer;
