import { FunctionComponent, useCallback } from 'react';
import styles from './category-filters.module.scss';
import { formatCategory, sortedCategories } from '../../../utils/formatters';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getFilersCategories, setFilteredCategories } from '../../../store/productSlice';
import { CategoryEnum } from '../../../types/Product';

export const CategoryFilters: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const filteredCategories = useAppSelector(getFilersCategories);

    const allIsActive = filteredCategories.length === 0;

    const toggleCategory = useCallback((category: CategoryEnum) => () => {
        const getCategories = () => {
            if (filteredCategories.includes(category))
                return filteredCategories.filter(x => x !== category);

            return [...filteredCategories, category];
        };

        const newList = getCategories();
        dispatch(setFilteredCategories(newList));
    }, [dispatch, filteredCategories]);

    const cleanCategories = useCallback(() => {
        dispatch(setFilteredCategories([]));
    }, [dispatch]);

    return (
        <div className={styles.categoryFilters}>
            <span
                className={`${allIsActive && styles.active} ${styles.option} ${styles.all}`}
                onClick={cleanCategories}>Todos</span>
            {sortedCategories.map(x => (
                <span
                    key={x}
                    className={`${styles.option} ${filteredCategories.includes(x) && styles.active}`}
                    onClick={toggleCategory(x)}>{formatCategory(x)}</span>
            ))}
        </div>
    );
};
