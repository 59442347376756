import { OpenedHours, WeekDayEnum } from '../../../types/Settings';
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { generateOptions, isTheSameOpenedHours } from '../../../utils';
import { allWeekDays } from '../../../utils/formatters';
import { IconButton } from '../../utils/icon-button';
import { SelectField } from '../../utils/select-field';
import { FieldTime } from '../../utils/field-time';
import styles from './opened-hours.module.scss';

interface Props {
    value: OpenedHours[] | undefined;
    onChange: (val: OpenedHours[] | undefined) => void;
}


const sortedWeekDays = [WeekDayEnum.monday, WeekDayEnum.tuesday, WeekDayEnum.wednesday, WeekDayEnum.thursday, WeekDayEnum.friday, WeekDayEnum.saturday, WeekDayEnum.sunday];

const isInvalid = (val: OpenedHours): boolean => {
    if (!val.startTime || !val.endTime || !val.weekday)
        return true;

    const [startHour, startMinute, startSecond] = val.startTime.split(':').map(x => parseInt(x));
    const [endHour, endMinute, endSecond] = val.endTime.split(':').map(x => parseInt(x));

    if (startHour > endHour)
        return true;

    if (startHour === endHour && startMinute > endMinute)
        return true;

    return (startHour === endHour && startMinute === endMinute && startSecond > endSecond);
};

const weekDays = generateOptions(allWeekDays);

export const OpenedHoursField: FunctionComponent<Props> = props => {
    const [openedHours, setOpenedHours] = useState<OpenedHours[]>([]);

    useEffect(() => {
        if (!props.value || isTheSameOpenedHours(props.value, openedHours)) {
            return;
        }

        const lst = [...props.value].sort((a, b) => {
            const aIndex = sortedWeekDays.indexOf(a.weekday);
            const bIndex = sortedWeekDays.indexOf(b.weekday);

            return aIndex - bIndex;
        });

        setOpenedHours(lst);
    }, [openedHours, props.value]);

    const availableWeekDays = weekDays.filter(x => !openedHours.some(y => y.weekday === x.value));

    const saveAndPropagateNewValue = useCallback((lst: OpenedHours[]) => {
        setOpenedHours(lst);
        const valToPropagate = lst.some(isInvalid) ? undefined : lst;
        props.onChange(valToPropagate);
    }, [props]);

    const addDay = useCallback(() => {
        const newDay: OpenedHours = {
            startTime: '18:00:00',
            endTime: '23:00:00',
            weekday: availableWeekDays[0].value
        };
        const lst = [newDay, ...openedHours];
        saveAndPropagateNewValue(lst);
    }, [availableWeekDays, openedHours, saveAndPropagateNewValue]);

    const removeDay = useCallback((day: OpenedHours) => {
        const lst = openedHours.filter(x => x.weekday !== day.weekday);
        saveAndPropagateNewValue(lst);
    }, [openedHours, saveAndPropagateNewValue]);

    const changeDayInfo = useCallback((key: WeekDayEnum, newVal: OpenedHours) => {
        const position = openedHours.map(x => x.weekday).indexOf(key);
        const lst = openedHours.filter(x => x.weekday !== key);

        lst.splice(position, 0, newVal);
        saveAndPropagateNewValue(lst);
    }, [openedHours, saveAndPropagateNewValue]);

    const changeWeekDay = useCallback((lastVal: WeekDayEnum, newVal: WeekDayEnum) => {
        const obj: OpenedHours = {
            ...openedHours.find(x => x.weekday === lastVal)!,
            weekday: newVal
        };

        changeDayInfo(lastVal, obj);
    }, [changeDayInfo, openedHours]);

    const changeStartTime = useCallback((key: WeekDayEnum, newStartTime: string) => {
        const obj: OpenedHours = {
            ...openedHours.find(x => x.weekday === key)!,
            startTime: newStartTime
        };

        changeDayInfo(key, obj);
    }, [changeDayInfo, openedHours]);

    const changeEndTime = useCallback((key: WeekDayEnum, newEndTime: string) => {
        const obj: OpenedHours = {
            ...openedHours.find(x => x.weekday === key)!,
            endTime: newEndTime
        };

        changeDayInfo(key, obj);
    }, [changeDayInfo, openedHours]);

    return (
        <>
            <div className={styles.openedHoursHeader}>
                <h3>Dias de abertura</h3>
                {!!availableWeekDays.length && (
                    <IconButton icon="add" size="big" onClick={addDay} />
                )}
            </div>
            <div className={styles.openedHours}>
                {openedHours.map((day, i) => {
                    const possibleDays = weekDays.filter(x => !openedHours.some(y => y.weekday === x.value) || x.value === day.weekday);
                    return (
                        <Fragment key={i}>
                            <SelectField
                                options={possibleDays}
                                onChange={changeWeekDay.bind(this, day.weekday)}
                                value={day.weekday} />

                            <FieldTime
                                onChange={changeStartTime.bind(this, day.weekday)}
                                label="Abertura"
                                value={day.startTime} />
                            <FieldTime
                                onChange={changeEndTime.bind(this, day.weekday)}
                                label="Fechamento"
                                value={day.endTime} />

                            <IconButton
                                icon="delete"
                                size="big"
                                onClick={removeDay.bind(this, day)} />
                        </Fragment>
                    );
                })}
            </div>
        </>
    );
};
