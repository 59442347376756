import React, { FunctionComponent, useCallback, useState } from 'react';
import { Card } from '../utils/card';
import styles from './products.module.scss';
import { HeaderApp } from '../utils/header';
import { Product } from '../../types/Product';
import { useSelector } from 'react-redux';
import {
    bumpSortIndex,
    deleteProduct,
    downgradeSortIndex,
    downloadProductsList,
    getIsLastFromCategory,
    getProductIsLoading,
    getProductsTotal,
    getSortedAndFilterProducts,
    toggleProductIsEnabled
} from '../../store/productSlice';
import { Paginator } from '../utils/paginator';
import { useNavigate } from 'react-router-dom';
import AddIcon from '../../assets/images/icons/add.svg';
import EditIcon from '../../assets/images/icons/edit.svg';
import TrashIcon from '../../assets/images/icons/delete.svg';
import { FloatingActionButton } from '../utils/floating-action-button';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { DropdownItem, Dropdowns } from '../utils/dropdowns';
import { openConfirmation } from '../../store/appSlice';
import { Switch } from '../utils/switch';
import { SpinLoading } from '../utils/spin-loading';
import { IconButton } from '../utils/icon-button';
import { BasePagination, basicPagination } from '../../utils/pagination';
import { With } from '../../types';
import { formatAnnotation, formatCategory, formatPriceAmount } from '../../utils/formatters';
import { CategoryFilters } from './CategoryFilters';

export const Products = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const lst = useSelector(getSortedAndFilterProducts(pagination));
    const total = useSelector(getProductsTotal);

    const downloadReport = useCallback(() => {
        dispatch(downloadProductsList());
    }, [dispatch]);

    const renderTable = () => {
        if (!total)
            return <div>Nenhum produto encontrado</div>;

        return (
            <div className={styles.tableProducts}>
                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Categoria</th>
                        <th>Preço</th>
                        <th>Opções</th>
                        <th>Anotações</th>
                        <th>Disponibilidade</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {lst.map(x => (
                        <ProductItem key={x.id} data={x} />
                    ))}
                    </tbody>
                </table>
                <Paginator pagination={pagination} totals={total} setPage={setPagination} />
            </div>
        );
    };

    return (
        <Card className={styles.products}>
            <HeaderApp title="Products">
                <IconButton icon="download" onClick={downloadReport} />
            </HeaderApp>
            {!!total && (<CategoryFilters />)}
            {renderTable()}
            <FloatingActionButton icon={AddIcon} onClick={() => navigate('/products/new')} />
        </Card>
    );
};

const ProductItem: FunctionComponent<With<Product>> = ({ data }) => {
    const [sortIndexIsLoading, setSortIndexIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getProductIsLoading(data.id));
    const isLast = useAppSelector(getIsLastFromCategory(data));
    const isFirst = data.sortIndex === 0;

    const openProduct = useCallback(() => navigate(`/products/${data.id}/edit`), [data.id, navigate]);

    const handleSwitchClick = useCallback(() => {
        dispatch(toggleProductIsEnabled(data));
    }, [data, dispatch]);

    const bumpUp = useCallback(async () => {
        setSortIndexIsLoading(true);
        try {
            await dispatch(bumpSortIndex(data.id));
        } catch (err) {

        }
        setSortIndexIsLoading(false);
    }, [data, dispatch]);

    const bumpDown = useCallback(async () => {
        setSortIndexIsLoading(true);

        try {
            await dispatch(downgradeSortIndex(data.id));
        } catch (err) {

        }
        setSortIndexIsLoading(false);
    }, [data, dispatch]);

    return (
        <tr>
            <td data-title="#">
                {sortIndexIsLoading && (<SpinLoading />)}
                {!sortIndexIsLoading && (
                    <div className={styles.sortIndex}>
                        <IconButton icon="arrow-up" onClick={bumpUp} disabled={isFirst} />
                        <IconButton icon="arrow-down" onClick={bumpDown} disabled={isLast} />
                    </div>
                )}
            </td>
            <td data-title="Nome" onClick={openProduct}>{data.name}</td>
            <td data-title="Categoria" onClick={openProduct}>{formatCategory(data.category)}</td>
            <td data-title="Preço" onClick={openProduct}>{formatPriceAmount(data.price)}</td>
            <td data-title="Opções" onClick={openProduct}>{data.options?.join(', ')}</td>
            <td data-title="Anotações" onClick={openProduct}>{data.annotations.map(formatAnnotation).join(', ')}</td>
            <td data-title="Disponibilidade">
                <div className={styles.isEnabled}>
                    {isLoading && (<SpinLoading />)}
                    {!isLoading && (<Switch onChange={handleSwitchClick} value={data.isEnabled} />)}
                </div>
            </td>
            <td>
                <DropdownProduct data={data} />
            </td>
        </tr>
    );
};

const DropdownProduct: FunctionComponent<With<Product>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id } = data;

    const editProductOption: DropdownItem = {
        icon: EditIcon,
        onClick: () => navigate(`/products/${id}/edit`),
        label: 'Editar'
    };

    const removeProductOption: DropdownItem = {
        icon: TrashIcon,
        onClick: () => dispatch(openConfirmation({
            title: `Remover ${data.name}`,
            text: `Você confirma a deleção do produto ${data.name}?`,
            onConfirm: () => dispatch(deleteProduct(data)),
        })),
        label: 'Remover'
    };

    return <Dropdowns options={[editProductOption, removeProductOption]} />;
};
