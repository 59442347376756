export enum WeekDayEnum {
    sunday = 'sunday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday'
}

export enum MessageProvider {
    telegram = 'telegram',
    smsTwilio = 'smsTwilio',
    whatsappTwilio = 'whatsappTwilio',
    comtele = 'comtele',
    zenvia = 'zenvia'
}

export interface Contacts {
    whatsapp: string;
}

export interface OpenedHours {
    weekday: WeekDayEnum;
    startTime: string;
    endTime: string;
}

export interface Settings {
    minimumPurchaseValue: number;
    contacts: Contacts;
    openedHours: OpenedHours[];
    estimatedTimeToDeliverInMinutes: number;
    messageProvider: MessageProvider;
}
