import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Field } from '../field';

interface Props {
    value?: number;
    onChange: (val: number) => void;
    label: string;
    placeHolder: string;
    disabled?: boolean;
}

export const FieldNumber: FunctionComponent<Props> = props => {
    const [val, setVal] = useState('');

    const handleChange = useCallback((value: string) => {
        const onlyNumbers = value.match(/(\d|\.)/g)?.join('') || '';

        setVal(onlyNumbers);
        props.onChange(Number(onlyNumbers));
    }, [setVal, props]);

    useEffect(() => {
        if (Number(val) === props.value)
            return;

        setVal(props.value?.toString() || '');
    }, [setVal, props, val]);

    return (
        <Field
            label={props.label}
            disabled={props.disabled}
            placeholder={props.placeHolder}
            value={val}
            onChange={handleChange}
        />
    );
};
