import React, { ReactElement } from 'react';
import './App.scss';
import { Login } from './components/Login';
import { Alerts } from './components/app/alerts';
import { useAppSelector } from './hooks';
import { getLoggedUser } from './store/authSlice';
import { SnackBar } from './components/app/snack-bar';
import { RenewTokenHelper } from './components/app/renew-token-helper';
import { ChangePassword } from './components/app/change-password';
import { About } from './components/app/about';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { BackofficeUser, Role } from './types/BackofficeUser';
import { AppBar } from './components/app/app-bar';
import { Confirmation } from './components/app/confirmation';
import { getRoutes, getRoutesWithMenu } from './utils/routes';
import { HiddenLogoffButton } from './components/app/hidden-logoff-button';

const renderRoutes = (loggedUser: BackofficeUser): ReactElement => {
    const routesWithMenu = getRoutesWithMenu(loggedUser);
    const routes = getRoutes(loggedUser);

    return (
        <Route
            element={
                <>
                    {loggedUser.role !== Role.kitchen && (<AppBar />)}
                    <Outlet />
                </>
            }>
            {routes.map(route => (
                <Route path={route.path} key={route.path} element={<route.Component />} />
            ))}
            <Route path="*" element={<Navigate to={routesWithMenu[0].path} />} />
        </Route>
    );
};

function App() {
    const loggedUser = useAppSelector(getLoggedUser);

    if (!loggedUser) {
        return (
            <>
                <Alerts />
                <Login />
            </>
        );
    }


    return (
        <div className="App">
            <Alerts />
            <SnackBar />
            <RenewTokenHelper />
            <ChangePassword />
            <Confirmation />
            <BrowserRouter>
                <About />
                <Routes>
                    {renderRoutes(loggedUser)}
                </Routes>
            </BrowserRouter>
            <HiddenLogoffButton />
        </div>
    );
}

export default App;
