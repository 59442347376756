import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getOperator, updateOperator } from '../../store/operatorSlice';
import { SpinLoading } from '../utils/spin-loading';
import { BaseFormOperator } from './BaseFormOperator';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderApp } from '../utils/header';
import { Card } from '../utils/card';
import { Breadcrumb } from '../utils/breadcrumb';
import { BackofficeBasicUser, UpdateOperatorDto } from '../../types/BackofficeUser';

export const EditOperator: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const operator = useAppSelector(getOperator(id));

    const handleAction = (obj: BackofficeBasicUser) => {
        const payload: UpdateOperatorDto = {
            ...obj,
            id: id!
        };
        dispatch(updateOperator(payload))
            .then(() => navigate(-1));
    };

    if (!operator)
        return <div><SpinLoading /></div>;

    const breadcrumb = [
        { label: 'Operators', link: '/operators' },
        { label: operator.name }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumb} />
            <Card>
                <HeaderApp title={`Editar ${operator.name}`} />
                <BaseFormOperator labelAction="Atualizar" onAction={handleAction} value={operator} />
            </Card>
        </>
    );

};
