import React, { FunctionComponent } from 'react';
import styles from './card.module.scss';

interface Props {
    children: React.ReactNode;
    title?: string;
    style?: 'primary' | 'default';
    className?: string;
    classNameBody?: string;
}

export const Card: FunctionComponent<Props> = props => {
    const styleChosen = (props.style || 'default') === 'primary' ? styles.primary : styles.default;

    return (
        <div className={`${styles.card} ${styleChosen} ${props.className}`}>
            {props.title && (<h3 className={styles.cardTitle}>{props.title}</h3>)}
            <div className={`${styles.cardBody} ${props.classNameBody || ''}`}>{props.children}</div>
        </div>
    );
};
