import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { Order, StatusEnum } from '../types/Order';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Websocket } from '../services/Websocket';
import OrderService from '../services/OrderService';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { BasePagination, getPaged } from '../utils/pagination';
import { Columns, downloadCsv } from '../utils/report';
import {
    formatDeliveryInfo,
    formatPaymentInfo,
    formatPriceAmount,
    formatStatus,
    getTotalValue
} from '../utils/formatters';

const ORDERS_KEY = 'orders';
const ORDERS_LAST_UPDATED_DATE_KEY = 'orders_last_updated';

interface OrderState {
    lstOrders: Order[];
    loadingOrders: string[];
}

const initialState: OrderState = {
    lstOrders: [],
    loadingOrders: []
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        upsertOrder: (state, action: PayloadAction<Order>) => {
            state.lstOrders = upsertOnList(state.lstOrders, action.payload);
        },
        cleanListOrders: state => {
            state.lstOrders = [];
        },
        setOrderIsLoading: (state, action: PayloadAction<string>) => {
            state.loadingOrders = [...state.loadingOrders, action.payload];
        },
        removeOrderIsLoading: (state, action: PayloadAction<string>) => {
            state.loadingOrders = state.loadingOrders.filter(x => x !== action.payload);
        }
    }
});

const { upsertOrder, setOrderIsLoading, removeOrderIsLoading, cleanListOrders } = orderSlice.actions;

export const bumpStatus = (id: string): AppThunk => dispatch => {
    dispatch(setOrderIsLoading(id));
    OrderService.bumpStatus(id)
        .finally(() => {
            dispatch(removeOrderIsLoading(id));
        });
};

export const downloadOrdersList = (): AppThunk => (dispatch, getState) => {
    const columns: Columns<Order> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Status', getValue: formatStatus },
        { title: 'Pagamento', getValue: formatPaymentInfo },
        { title: 'Entrega', getValue: formatDeliveryInfo },
        { title: 'Nome Cliente', getValue: x => x.client.name },
        { title: 'Telefone Cliente', getValue: x => x.client.phoneNumber },
        { title: 'Email Cliente', getValue: x => x.client.email },
        { title: 'Chave Cupom', getValue: x => x.discountCoupon?.key },
        { title: 'Desconto', getValue: x => formatPriceAmount(x.discountCoupon?.discountValue) },
        { title: 'Valor Total', getValue: getTotalValue },
        { title: 'Produtos', getValue: x => x.products.map(p => `${p.quantity} x ${p.product.name}`).join(', ') },
        { title: 'Criado em', getValue: x => x.createdAt.toLocaleString() },
        { title: 'Atualizado em', getValue: x => x.updatedAt.toLocaleString() }
    ];

    const lst = getState().order.lstOrders;
    downloadCsv(lst, columns, 'orders.csv');
};

export const setupOrderState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Order>('update-order', order => {
        dispatch(upsertOrder(order));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(ORDERS_LAST_UPDATED_DATE_KEY);
    Websocket.emitOnConnection('subscribe-orders', payload);
};

const updateOnStorage = async (getState: () => RootState) => {
    await delay(100);

    const orders = getState().order.lstOrders;
    saveOnLocalStorage(ORDERS_KEY, orders);
    saveLastUpdatedDate(ORDERS_LAST_UPDATED_DATE_KEY, orders);
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Order[]>(ORDERS_KEY);
    lst?.forEach(order => dispatch(upsertOrder(order)));
};

export const cleanOrderStateAndStorage = (): AppThunk => dispatch => {
    dispatch(cleanListOrders());
    removeOnLocalStorage(ORDERS_KEY);
    removeOnLocalStorage(ORDERS_LAST_UPDATED_DATE_KEY);
};

export const getSortedOrders = (states?: StatusEnum[]) => (root: RootState): Order[] => {
    const parsedLst = root.order.lstOrders;

    const lst = parsedLst.filter(x => !states || states.includes(x.status));

    return lst.sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? 1 : -1);
};

export const getOrder = (id: string | undefined) => (root: RootState): Order | undefined =>
    root.order.lstOrders.find(x => x.id === id);

export const getOrders = (pagination: BasePagination) => (root: RootState): Order[] => {
    const lst = root.order.lstOrders;
    return getPaged(pagination, lst);
};

export const getTotalOrders = (root: RootState): number => root.order.lstOrders.length;

export const getOrderIsLoading = (orderId: string) => (root: RootState): boolean =>
    root.order.loadingOrders.includes(orderId);

export const getOrdersByClient = (clientId: string) => (root: RootState): Order[] =>
    root.order.lstOrders.filter(x => x.client.id === clientId);

export default orderSlice.reducer;
