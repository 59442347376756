import { BackofficeUser } from '../types/BackofficeUser';
import { HasId, SelectFieldOption } from '../types';
import { OpenedHours } from '../types/Settings';

export const extractNumber = (val: string | undefined): string =>
    val?.match(/\d/g)?.join('') || '';

export const getUrlGenerator = (baseUrl: string | undefined) => (...args: (string | number | object)[]) => {
    const params = args
        .map(x => {
            if (typeof x === 'object') {
                const urlParams = new URLSearchParams(x as Record<any, any>);

                const query = Array.from(urlParams.keys())
                    .filter(x => urlParams.get(x) !== 'undefined')
                    .map(x => `${x}=${urlParams.get(x)}`)
                    .join('&');

                return `?${query}`;
            }

            return x;
        })
        .join('/');

    return `${baseUrl}/${params}`;
};

export const generateUuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const delay = (time: number): Promise<void> =>
    new Promise<void>(resolve => setTimeout(resolve, time));


export const userFromToken = (token: string): BackofficeUser | undefined => {
    if (!token)
        return;

    const payloadToken = token.split('.')[1];

    if (!payloadToken)
        return;

    const decoded = window.atob(payloadToken);
    return JSON.parse(decoded);
};

export const isValidDateString = (value: any): boolean =>
    /^(\d{4})-(\d{2})-(\d{2})([T\s](\d{2}):(\d{2}):(\d{2})(\.(\d+)(Z)?)?)?$/.test(value);

export function apiResponseFormatter<T>(obj: T): T {
    if (!obj) return obj;

    if (Array.isArray(obj)) {
        return obj.map(i => apiResponseFormatter(i)) as any;
    }

    if (typeof obj === 'string' && isValidDateString(obj)) {
        return new Date(obj) as any;
    }

    if (typeof obj === 'object' && !(obj instanceof Date)) {
        return Object.keys(obj).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = apiResponseFormatter(obj[key]);
            return acc;
        }, {}) as T;
    }

    return obj;
}

export type VoidCallback = () => void


export const upsertOnList = <T extends HasId>(lst: T[], obj: T): T[] => upsertOnListCustom(lst, obj, x => x.id);

export const upsertOnListCustom = <T, U>(lst: T[], obj: T, getId: (obj: T) => U): T[] => {
    const position = lst.map(x => getId(x)).indexOf(getId(obj));

    if (position === -1)
        return [...lst, obj];

    const newList = lst.filter(x => getId(x) !== getId(obj));
    newList.splice(position, 0, obj);

    return newList;
};

export const distinctBy = <T, U>(lst: T[], selector: (obj: T) => U): T[] =>
    lst.filter((x, i, lst) => lst.map(selector).indexOf(selector(x)) === i);


export const saveOnLocalStorage = <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const retrieveFromLocalStorage = <T>(key: string): T | undefined => {
    const stringPayload = localStorage.getItem(key);

    if (!stringPayload)
        return;

    const result: T = JSON.parse(stringPayload);
    return apiResponseFormatter(result);
};


export const generateOptions = <T extends string | number>(
    lst: Record<T, string>
): SelectFieldOption<T>[] =>
    Object.keys(lst)
        .map(key => ({ value: key as T, label: lst[key as T] }));

export const getEnumKeys = <T extends string | number>(obj: Record<T, String>): T[] =>
    Object.keys(obj)
        .map(key => key as T);

export const isTheSameOpenedHours = (a: OpenedHours[] | undefined, b: OpenedHours[] | undefined) => {
    if (!a)
        return !b;

    if (!b)
        return false;

    if (a.length !== b.length) {
        return false;
    }

    return !a.some(x => !b.some(y => y.weekday === x.weekday && y.startTime === x.startTime && y.endTime === x.endTime));
};

