import { Api } from './Api';
import { CreateProductDto, Product, UpdateProductDto } from '../types/Product';
import { getUrlGenerator } from '../utils';

const baseUrl = process.env.REACT_APP_PRODUCT_API!;
const url = getUrlGenerator(baseUrl);

export default class ProductService {
    public static create = (obj: CreateProductDto): Promise<Product> =>
        Api.post<Product>(url(), obj);

    public static update = (obj: UpdateProductDto): Promise<Product> =>
        Api.put<Product>(url(), obj);

    public static bumpSortIndex = (id: string): Promise<void> =>
        Api.put(url('bumpSortIndex'), { id });

    public static downgradeSortIndex = (id: string): Promise<void> =>
        Api.put(url('downgradeSortIndex'), { id });

    public static delete = (id: string): Promise<void> =>
        Api.delete(url(id));

    public static toggleProductIsEnabled = (obj: Product): Promise<Product> => {
        const isEnabled = !obj.isEnabled;

        const dto: UpdateProductDto = {
            id: obj.id,
            name: obj.name,
            description: obj.description,
            category: obj.category,
            isEnabled,
            annotations: obj.annotations,
            quantity: obj.quantity,
            options: obj.options,
            price: obj.price,
            idImage: obj.idImage,
        };

        return ProductService.update(dto);
    };
}
