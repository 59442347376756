import React, { useCallback } from 'react';
import { Card } from '../../utils/card';
import { HeaderApp } from '../../utils/header';
import { useAppDispatch } from '../../../hooks';
import { createProduct } from '../../../store/productSlice';
import { BaseProductDto, CreateProductDto } from '../../../types/Product';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';
import { BaseFormProduct } from '../BaseFormProduct';

export const AddProduct = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleAction = useCallback((obj: BaseProductDto) => {

        const payload: CreateProductDto = {
            ...obj,
            newImageBase64: obj.newImageBase64!
        };

        dispatch(createProduct(payload))
            .then(() => navigate('/products'));
    }, [dispatch, navigate]);

    const breadcrumb: BreadcrumbItem[] = [
        { label: 'Produtos', link: '/products' },
        { label: 'Novo produto' }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumb} />
            <Card>
                <HeaderApp title="Criar um produto" />
                <BaseFormProduct labelAction="Inserir" onAction={handleAction} />
            </Card>
        </>
    );
};
