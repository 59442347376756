import React, { FunctionComponent } from 'react';
import CloseIcon from '../../../assets/images/icons/close.svg';
import styles from './removable-image.module.scss';

interface Prop {
    onDelete: () => void;
    imageSrc: string;
    label: string;
}

export const RemovableImage: FunctionComponent<Prop> = props => {
    return (
        <div className={styles.removableImage}>
            <div className={styles.wrapperImage}>
                <img src={props.imageSrc} alt={props.label} />
            </div>
            <img src={CloseIcon} onClick={props.onDelete} className={styles.close} />
        </div>
    );
};
