import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProduct, updateProduct } from '../../../store/productSlice';
import React, { useCallback } from 'react';
import { BaseProductDto } from '../../../types/Product';
import { useAppDispatch } from '../../../hooks';
import { Card } from '../../utils/card';
import { HeaderApp } from '../../utils/header';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';
import { BaseFormProduct } from '../BaseFormProduct';
import { SpinLoading } from '../../utils/spin-loading';

export const EditProduct = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const product = useSelector(getProduct(id));

    const handleAction = useCallback((obj: BaseProductDto) => {
        dispatch(updateProduct({ ...obj, id: id! }))
            .then(() => navigate(-1));
    }, [dispatch, id, navigate]);

    if (!product)
        return <div><SpinLoading /></div>;

    const breadcrumb: BreadcrumbItem[] = [
        { label: 'Products', link: '/products' },
        { label: product.name }
    ];
    return (
        <>
            <Breadcrumb data={breadcrumb} />
            <Card>
                <HeaderApp title={`Editar ${product.name}`} />
                <BaseFormProduct labelAction="Atualizar" onAction={handleAction} value={product} />
            </Card>
        </>
    );
};
