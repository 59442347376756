import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCoupon, getCouponIsLoading, updateCoupon } from '../../../store/couponsSlice';
import { UpdateCouponDto } from '../../../types/DIscountCoupon';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';
import { Card } from '../../utils/card';
import { HeaderApp } from '../../utils/header';
import styles from '../CreateCoupon/create-coupon.module.scss';
import { FieldNumber } from '../../utils/field-number';
import { AutocompleteField } from '../../utils/autocomplete-field';
import { FieldDate } from '../../utils/field-date';
import { Button } from '../../utils/button';
import { getClient, getClientSearcher } from '../../../store/clientSlice';

export const EditCoupon: FunctionComponent = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getCouponIsLoading);

    const navigate = useNavigate();

    const stored = useAppSelector(getCoupon(id));

    const [clientId, setClientId] = useState<string>();
    const [limitQuantity, setLimitQuantity] = useState<number>();
    const [startValidPeriod, setStartValidPeriod] = useState<Date>();
    const [finalValidPeriod, setFinalValidPeriod] = useState<Date>();

    useEffect(() => {
        if (!stored)
            return;

        setClientId(stored.clientId);
        setLimitQuantity(stored.limitQuantity);
        setStartValidPeriod(stored.startValidPeriod);
        setFinalValidPeriod(stored.finalValidPeriod);
    }, [stored]);

    const handleAction = useCallback(
        () => {
            const payload: UpdateCouponDto = {
                id: id!,
                clientId,
                limitQuantity,
                startValidPeriod,
                finalValidPeriod,
            };

            dispatch(updateCoupon(payload))
                .then(() => {
                    navigate('/coupons');
                });
        },
        [clientId, dispatch, finalValidPeriod, id, limitQuantity, navigate, startValidPeriod]
    );

    const client = useAppSelector(getClient(clientId));

    if (!stored)
        return (<div>Cumpo não encontrado</div>);

    const didChange =
        clientId !== stored?.clientId
        || limitQuantity !== stored?.limitQuantity
        || startValidPeriod !== stored?.startValidPeriod
        || finalValidPeriod !== stored?.finalValidPeriod;

    const isDisabled = !didChange;

    const breadcrumb: BreadcrumbItem[] = [
        { label: 'Cupons', link: '/coupons' },
        { label: `Editar Cupom ${stored.key}` }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumb} />
            <Card>
                <HeaderApp title="Editar Cupom" />

                <div className={styles.createCoupon}>
                    <AutocompleteField
                        label="Client"
                        placeholder="Jhon maclane"
                        onChange={client => setClientId(client?.id)}
                        value={client}
                        searcher={getClientSearcher}
                        getLabel={x => x.name}
                    />
                    <FieldNumber
                        label="Quantidade limite"
                        placeHolder="13"
                        onChange={setLimitQuantity}
                        value={limitQuantity} />

                    <FieldDate
                        label="Inicio periodo de validade"
                        value={startValidPeriod}
                        onChange={setStartValidPeriod} />

                    <FieldDate
                        label="Final periodo de validade"
                        value={finalValidPeriod}
                        onChange={setFinalValidPeriod} />

                    <div className={styles.actions}>
                        <Button
                            isLoading={isLoading}
                            label="Salvar"
                            disabled={isDisabled}
                            onClick={handleAction} />
                    </div>
                </div>
            </Card>
        </>
    );
};
