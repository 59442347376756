import { CreateCouponDto, DiscountCoupon, UpdateCouponDto } from '../types/DIscountCoupon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Websocket } from '../services/Websocket';
import { Columns, downloadCsv } from '../utils/report';
import { formatCouponType, formatCouponUseType, formatCouponValue, formatPriceAmount } from '../utils/formatters';
import { getClient } from './clientSlice';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { BasePagination, getPaged } from '../utils/pagination';
import { getOperator } from './operatorSlice';
import { DiscountCouponService } from '../services/DiscountCouponService';
import { showAlert } from './notificationSlice';

const COUPONS_KEY = 'coupons';
const COUPONS_LAST_UPDATED_DATE = 'coupons_last_updated';

interface CouponsState {
    lstCoupons: DiscountCoupon[];
    couponsIsLoading: boolean;
}

const initialState: CouponsState = {
    lstCoupons: [],
    couponsIsLoading: false
};

const couponsSlice = createSlice({
    name: 'coupons',
    initialState,
    reducers: {
        upsertCoupon: (state, action: PayloadAction<DiscountCoupon>) => {
            state.lstCoupons = upsertOnList(state.lstCoupons, action.payload);
        },
        removeCoupon: (state, action: PayloadAction<string>) => {
            state.lstCoupons = state.lstCoupons.filter(x => x.id !== action.payload);
        },
        updateCouponList: (state, action: PayloadAction<DiscountCoupon[]>) => {
            state.lstCoupons = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.couponsIsLoading = action.payload;
        },
    }
});

export const {
    upsertCoupon,
    removeCoupon,
    updateCouponList,
    setIsLoading
} = couponsSlice.actions;

export const updateCoupon = (coupon: UpdateCouponDto): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    return DiscountCouponService.update(coupon)
        .then(() => {
            showAlert('Cupom atualizado com sucesso');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const createCoupon = (coupon: CreateCouponDto): AppThunk<Promise<void>> => (dispatch, getState) => {
    dispatch(setIsLoading(true));

    return DiscountCouponService.create(coupon)
        .then(() => {
            showAlert('Cupom criado com sucesso');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const deleteCoupon = (coupon: DiscountCoupon): AppThunk<Promise<void>> => (dispatch, getState) => {
    dispatch(setIsLoading(true));

    return DiscountCouponService.delete(coupon.id)
        .then(() => {
            showAlert('Cupom excluído com sucesso');
        })
        .finally(() => dispatch(setIsLoading(false)));

};

export const downloadCoupons = (): AppThunk => async (dispatch, getState) => {
    const lst = getState().coupons.lstCoupons;

    const rootState = getState();

    const columns: Columns<DiscountCoupon> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Chave', getValue: x => x.key },
        { title: 'Tipo', getValue: x => formatCouponType(x.type) },
        { title: 'Desconto', getValue: x => formatCouponValue(x) },
        { title: 'Cliente', getValue: x => getClient(x.clientId)(rootState)?.name },
        { title: 'Forma de uso', getValue: x => formatCouponUseType(x.useType) },
        { title: 'Quantidade limite', getValue: x => x.limitQuantity?.toString() },
        { title: 'Inicio periodo de validade', getValue: x => x.startValidPeriod?.toLocaleDateString() },
        { title: 'Final periodo de validade', getValue: x => x.finalValidPeriod?.toLocaleDateString() },
        { title: 'Minimo valor da compra', getValue: x => formatPriceAmount(x.minPurchaseValue) },
        { title: 'Quantidade usada', getValue: x => x.qtyUsed.toString() },
        { title: 'Data de criação', getValue: x => x.createdAt.toLocaleDateString() },
        { title: 'Criado por', getValue: x => getOperator(x.createdBy)(rootState)?.name || x.createdBy },
        { title: 'Atualizado em', getValue: x => x.updatedAt.toLocaleDateString() },
    ];

    downloadCsv(lst, columns, 'cupons.csv');
};

export const cleanCouponStateAndStorage = (): AppThunk => (dispatch, getState) => {
    dispatch(updateCouponList([]));
    removeOnLocalStorage(COUPONS_KEY);
    removeOnLocalStorage(COUPONS_LAST_UPDATED_DATE);
};

export const setupCouponState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<DiscountCoupon>('update-discount-coupon', coupon => {
        dispatch(upsertCoupon(coupon));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<{ id: string }>('delete-discount-coupon', ({ id }) => {
        dispatch(removeCoupon(id));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(COUPONS_LAST_UPDATED_DATE);
    Websocket.emitOnConnection('subscribe-discount-coupons', payload);
};

const updateOnStorage = async (getState: () => RootState) => {
    await delay(100);

    const coupons = getState().coupons.lstCoupons;
    saveOnLocalStorage(COUPONS_KEY, coupons);
    saveLastUpdatedDate(COUPONS_LAST_UPDATED_DATE, coupons);
};

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const lst = retrieveFromLocalStorage<DiscountCoupon[]>(COUPONS_KEY);

    if (!lst)
        return;

    lst.forEach(coupon => dispatch(upsertCoupon(coupon)));
};

export const getCouponsTotal = (state: RootState): number => state.coupons.lstCoupons.length;
export const getCoupons = (page: BasePagination) => (state: RootState): DiscountCoupon[] => {
    const lst = state.coupons.lstCoupons;
    return getPaged(page, lst);
};

export const getCoupon = (id: string | undefined) => (state: RootState): DiscountCoupon | undefined =>
    state.coupons.lstCoupons.find(x => x.id === id);

export const getCouponIsLoading = (state: RootState): boolean =>
    state.coupons.couponsIsLoading;

export default couponsSlice.reducer;
