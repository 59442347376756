import React, { useCallback, useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnclickOutside';
import styles from './selec-field.module.scss';
import { SelectFieldOption } from '../../../types';

interface Props<T> {
    options: SelectFieldOption<T>[];
    onChange: (obj: T) => void;
    value: T;
    label?: string;
}

export const SelectField = <T extends any>({ options, onChange, value, label }: Props<T>) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpened = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    useOnClickOutside(ref, () => {
        if (!isOpen)
            return;

        toggleOpened();
    });

    const selectedOption = options.find(x => x.value === value);

    const renderItem = (data: SelectFieldOption<T>, key: number) => {
        const onClick = () => {
            setIsOpen(false);
            onChange(data.value);
        };
        const styleActive = selectedOption?.value === data.value ? styles.active : '';

        return <span key={key} onClick={onClick} className={styleActive}>{data.label}</span>;
    };

    const styleOpen = isOpen ? styles.open : '';
    return (
        <div className={`${styles.selectField} ${styleOpen}`} onClick={toggleOpened} ref={ref}>
            <div className={styles.label}>
                <span className={styles.label}>{label}</span>
            </div>
            <span>{selectedOption?.label || 'Selecione uma opção'}</span>
            <div className={styles.selectFieldOptions}>
                {options.map(renderItem)}
            </div>
        </div>
    );
};
