import { Dialog } from '../../utils/dialog';
import { cleanLocalStorageAndLogoff, getAboutSectionIsOpened, setAboutSectionIsOpened } from '../../../store/appSlice';
import { useSelector } from 'react-redux';
import styles from './about.module.scss';
import { useAppDispatch } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { Button } from '../../utils/button';
import MopIcon from '../../../assets/images/icons/mop.svg';

export const About = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getAboutSectionIsOpened);

    const cleanCache = useCallback(() => {
        dispatch(cleanLocalStorageAndLogoff())
            .then(() => navigate('./', { replace: true }));
    }, [dispatch, navigate]);

    return (
        <Dialog
            isOpen={isOpen}
            title="About"
            className={styles.about}
            onClose={() => dispatch(setAboutSectionIsOpened(false))}
        >
            <h3>Version: {process.env.REACT_APP_VERSION}</h3>

            <Button
                label="Limpar cache"
                onClick={cleanCache}
                prefix={<img alt="Mop Icon" src={MopIcon} style={{ filter: 'invert(1)', marginRight: '8px' }} />} />
        </Dialog>
    );
};
