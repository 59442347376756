interface Column<T> {
    title: string;
    getValue: (item: T) => string | undefined;
}

export type Columns<T> = Column<T>[];

const mapToCsv = <T>(columns: Column<T>[], lst: T[]) => {
    const separator = ';';
    const getLine = (x: T): string =>
        columns.map(col => col.getValue(x)).join(separator);

    const resultHead: string = columns.map(x => x.title).join(separator);
    const resultBody: string[] = lst.map(getLine);
    const lines = [resultHead, ...resultBody];
    return lines.join('\n');
};

const downloadData = (data: string, contentType: string, fileName: string): void => {
    const element = document.createElement('a');
    element.setAttribute('href', `data: ${contentType};charset=utf-8, ${encodeURIComponent(data)}`);
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const downloadCsv = <T>(lst: T[], columns: Column<T>[], fileName: string): void => {
    const content = mapToCsv(columns, lst);

    downloadData(content, 'text/csv', fileName);
};
