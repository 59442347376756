import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { AnnotationEnum, BaseProductDto, CategoryEnum, Product } from '../../../types/Product';
import { Field } from '../../utils/field';
import { Button } from '../../utils/button';
import styles from './base-form-product.module.scss';
import { AddImageButton } from '../../utils/add-image-button';
import StorageService from '../../../services/StorageService';
import { SelectField } from '../../utils/select-field';
import { RemovableImage } from '../../utils/removable-image';
import { Switch } from '../../utils/switch';
import { useAppSelector } from '../../../hooks';
import { getProductGeneralIsLoading, getProductIsLoading } from '../../../store/productSlice';
import { FieldNumber } from '../../utils/field-number';
import { allAnnotations, allCategories } from '../../../utils/formatters';
import { generateOptions } from '../../../utils';
import { SelectMultipleField } from '../../utils/select-multiple-field';
import { SelectFieldOption } from '../../../types';

interface Prop {
    value?: Product;
    labelAction: string;
    onAction: (obj: BaseProductDto) => void;
}

const categories = generateOptions(allCategories);
const lstAnnotations: SelectFieldOption<AnnotationEnum>[] = generateOptions(allAnnotations);

export const BaseFormProduct: FunctionComponent<Prop> = props => {
    const generalIsLoading = useAppSelector(getProductGeneralIsLoading);
    const productIsLoading = useAppSelector(getProductIsLoading(props.value?.id));

    const isLoading = (!props.value?.id && generalIsLoading) || productIsLoading;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState<CategoryEnum>(CategoryEnum.entries);
    const [price, setPrice] = useState(0);
    const [newImage, setNewImage] = useState<string>('');
    const [existentImage, setExistentImage] = useState<string>('');
    const [quantity, setQuantity] = useState<string>();
    const [options, setOptions] = useState<string[]>();
    const [annotations, setAnnotations] = useState<AnnotationEnum[]>([]);
    const [isEnabled, setIsEnabled] = useState<boolean>(false);

    const handleAction = useCallback(() => {
        const payload: BaseProductDto = {
            name,
            description,
            category,
            price,
            quantity,
            options,
            annotations,
            isEnabled,
            newImageBase64: newImage,
            idImage: !!props.value?.idImage ? existentImage : undefined
        };

        props.onAction(payload);
    }, [annotations, category, description, existentImage, isEnabled, name, newImage, options, price, props, quantity]);

    useEffect(() => {
        if (!props.value)
            return;

        const val = props.value;
        setName(val.name);
        setDescription(val.description);
        setCategory(val.category);
        setPrice(val.price);
        setExistentImage(val.idImage);
        setQuantity(val.quantity);
        setOptions(val.options);
        setAnnotations(val.annotations);
        setIsEnabled(val.isEnabled);
    }, [props.value]);

    const val = props.value;

    const optionsHasChanged = (!val?.options && !!options) ||
        options?.some(x => !val?.options?.includes(x));

    const didChange =
        name !== val?.name ||
        description !== val?.description ||
        category !== val?.category ||
        price !== val?.price ||
        !!newImage ||
        quantity !== val?.quantity ||
        optionsHasChanged ||
        annotations.length !== val?.annotations.length;

    const isDisabled =
        !didChange ||
        !name ||
        !description ||
        !category ||
        !price ||
        !(!!existentImage || !!newImage);

    return (
        <div className={styles.baseFormProduct}>
            <Switch onChange={setIsEnabled} value={isEnabled} className={styles.isEnabledToggle}>
                Disponivel
            </Switch>

            <Field
                label="Nome"
                placeholder="Costela Bovina"
                onChange={setName}
                value={name}
            />
            <Field
                label="Descrição"
                placeholder="Peito bovino defumado com lenha frutifera por 12 horas"
                onChange={setDescription}
                value={description}
            />
            <SelectField
                label="Categoria"
                options={categories}
                onChange={setCategory}
                value={category}
            />
            <FieldNumber
                label="Preço"
                placeHolder="32.12"
                onChange={setPrice}
                value={price} />

            <Field
                label="Quantidade"
                placeholder="300 Gramas"
                onChange={setQuantity}
                value={quantity} />

            <Field
                label="Opções"
                placeholder="Bem Passado, Mal Passado, Ao Ponto"
                onChange={val => setOptions(val.split(', '))}
                value={options?.join(', ')}
            />

            <SelectMultipleField
                options={lstAnnotations}
                onChange={setAnnotations}
                value={annotations}
                label="Anotações" />


            <div className={styles.images}>
                {existentImage && (
                    <RemovableImage
                        onDelete={() => setExistentImage('')}
                        imageSrc={StorageService.getUrl(existentImage)}
                        label="product image" />
                )}
            </div>

            <AddImageButton
                onChosen={img => {
                    setExistentImage('');
                    setNewImage(img[0]);
                }} />

            <div className={styles.actions}>
                <Button
                    isLoading={isLoading}
                    label={props.labelAction}
                    disabled={isDisabled}
                    onClick={handleAction} />
            </div>
        </div>
    );
};
