import './outlineButton.scss';
import React, { ReactElement } from 'react';
import { VoidCallback } from '../../../utils';

interface Props {
    label: string;
    onClick: VoidCallback;
    className?: string;
    prefix?: ReactElement;
}

export const OutlineButton: React.FC<Props> = ({ label, onClick, prefix, className }) => (
    <div className={`outline-button ${className}`} onClick={onClick}>
        {!!prefix && (prefix)}
        {label}
    </div>
);
