import { getUrlGenerator } from '../utils';
import { CreateCouponDto, DiscountCoupon, UpdateCouponDto } from '../types/DIscountCoupon';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_DISCOUNT_COUPON_API;

const url = getUrlGenerator(baseUrl);

export class DiscountCouponService {
    public static create = (coupon: CreateCouponDto): Promise<DiscountCoupon> => {
        return Api.post(url(), coupon);
    };

    public static update = (coupon: UpdateCouponDto): Promise<DiscountCoupon> => {
        return Api.put(url(), coupon);
    };

    public static delete = (id: string): Promise<void> => {
        return Api.delete(url(id));
    };
}
