import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    BackofficeBasicUser,
    BackofficeUser,
    NewlyCreatedBackofficeUser,
    UpdateOperatorDto
} from '../types/BackofficeUser';
import { delay, upsertOnList } from '../utils';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Websocket } from '../services/Websocket';
import { HasId } from '../types';
import { BasePagination, getPaged } from '../utils/pagination';
import { Columns, downloadCsv } from '../utils/report';
import { phoneFormatter } from '../utils/formatters';
import BackofficeUserService from '../services/BackofficeUserService';
import { showAlert } from './notificationSlice';

const OPERATORS_KEY = 'operators';

interface OperatorState {
    lstOperators: BackofficeUser[];
    isLoading: boolean;
    newlyCreatedUser: NewlyCreatedBackofficeUser | null;
}

const initialState: OperatorState = {
    lstOperators: [],
    isLoading: false,
    newlyCreatedUser: null
};

const operatorSlice = createSlice({
    name: 'operator',
    initialState,
    reducers: {
        upsertOperator: (state, action: PayloadAction<BackofficeUser>) => {
            state.lstOperators = upsertOnList(state.lstOperators, action.payload);
        },
        removeOperator: (state, action: PayloadAction<string>) => {
            state.lstOperators = state.lstOperators.filter(x => x.id !== action.payload);
        },
        updateOperatorList: (state, action: PayloadAction<BackofficeUser[]>) => {
            state.lstOperators = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setNewlyCreatedUser: (state, action: PayloadAction<NewlyCreatedBackofficeUser | null>) => {
            state.newlyCreatedUser = action.payload;

        },
        cleanNewlyCreatedUser: state => {
            state.newlyCreatedUser = null;
        }
    }
});

const {
    upsertOperator,
    removeOperator,
    updateOperatorList,
    setIsLoading,
    setNewlyCreatedUser
} = operatorSlice.actions;

export const { cleanNewlyCreatedUser } = operatorSlice.actions;

export const createOperator = (obj: BackofficeBasicUser): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    return BackofficeUserService.createOperator(obj)
        .then((x) => {
            dispatch(setNewlyCreatedUser(x));
            showAlert('Operador criado com sucesso');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const updateOperator = (obj: UpdateOperatorDto): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    return BackofficeUserService.updateOperator(obj)
        .then(() => {
            showAlert('Operador atualizado com sucesso');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const deleteOperator = (id: string): AppThunk => async dispatch => {
    dispatch(setIsLoading(true));

    BackofficeUserService.deleteOperator(id)
        .then(() => {
            showAlert('Operador removido com sucesso');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const downloadOperatorsList = (): AppThunk => async (dispatch, getState) => {
    const columns: Columns<BackofficeUser> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Nome', getValue: x => x.name },
        { title: 'Telefone', getValue: x => phoneFormatter(x.phoneNumber) },
    ];

    const lst = getState().operator.lstOperators;
    downloadCsv(lst, columns, 'operators.csv');
};

export const cleanOperatorStateAndStorage = (): AppThunk => dispatch => {
    dispatch(updateOperatorList([]));
    removeOnLocalStorage(OPERATORS_KEY);
};

export const setupOperatorState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<BackofficeUser>('update-operator-user', operator => {
        dispatch(upsertOperator(operator));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<HasId>('delete-operator-user', obj => {
        dispatch(removeOperator(obj.id));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<BackofficeUser[]>('update-all-operators', lst => {
        dispatch(updateOperatorList(lst));
        return updateOnStorage(getState);
    });
};

const updateOnStorage = async (getState: () => RootState) => {
    await delay(100);

    const lst = getState().operator.lstOperators;
    saveOnLocalStorage(OPERATORS_KEY, lst);
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<BackofficeUser[]>(OPERATORS_KEY);
    if (!lst)
        return;

    dispatch(updateOperatorList(lst));
};

export const getOperatorIsLoading = (state: RootState): boolean => state.operator.isLoading;

export const getOperator = (id: string | undefined) => (state: RootState): BackofficeUser | undefined =>
    state.operator.lstOperators.find(x => x.id === id);

export const getOperators = (page: BasePagination) => (state: RootState): BackofficeUser[] => {
    const lst = state.operator.lstOperators;
    return getPaged(page, lst);
};

export const getOperatorsTotal = (state: RootState): number => state.operator.lstOperators.length;

export const getOperatorNewlyCreated = (state: RootState): NewlyCreatedBackofficeUser | null => state.operator.newlyCreatedUser;

export default operatorSlice.reducer;
