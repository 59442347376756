import React, { FunctionComponent } from 'react';
import styles from './icon-button.module.scss';
import MoreIcon from '../../../assets/images/icons/more.svg';
import ArrowLeftIcon from '../../../assets/images/icons/arrow-left.svg';
import ArrowRightIcon from '../../../assets/images/icons/arrow-right.svg';
import ArrowDownIcon from '../../../assets/images/icons/arrow-down.svg';
import ArrowUpIcon from '../../../assets/images/icons/arrow-up.svg';
import DownloadIcon from '../../../assets/images/icons/cloud-download.svg';
import EditIcon from '../../../assets/images/icons/edit.svg';
import CancelIcon from '../../../assets/images/icons/cancel.svg';
import AddIcon from '../../../assets/images/icons/add.svg';
import DeleteIcon from '../../../assets/images/icons/delete.svg';
import CheckIcon from '../../../assets/images/icons/done.svg';
import CloseIcon from '../../../assets/images/icons/close.svg';
import SendIcon from '../../../assets/images/icons/send.svg';


type iconTypes =
    'more'
    | 'arrow-up'
    | 'arrow-down'
    | 'arrow-left'
    | 'arrow-right'
    | 'download'
    | 'edit'
    | 'cancel'
    | 'add'
    | 'delete'
    | 'check'
    | 'close'
    | 'send'
    ;

type styleTypes = 'default' | 'slim';
type sizeTypes = 'normal' | 'big';

interface Props {
    icon: iconTypes;
    onClick: Function;
    styleWeight?: styleTypes;
    size?: sizeTypes;
    disabled?: boolean;
    className?: string;
}

const iconOptions: { icon: iconTypes; image: any }[] = [
    { icon: 'more', image: MoreIcon },
    { icon: 'arrow-up', image: ArrowUpIcon },
    { icon: 'arrow-down', image: ArrowDownIcon },
    { icon: 'arrow-left', image: ArrowLeftIcon },
    { icon: 'arrow-right', image: ArrowRightIcon },
    { icon: 'download', image: DownloadIcon },
    { icon: 'edit', image: EditIcon },
    { icon: 'cancel', image: CancelIcon },
    { icon: 'check', image: CheckIcon },
    { icon: 'close', image: CloseIcon },
    { icon: 'cancel', image: CancelIcon },
    { icon: 'add', image: AddIcon },
    { icon: 'delete', image: DeleteIcon },
    { icon: 'send', image: SendIcon }
];

const styleOptions = new Map<styleTypes, string>();
styleOptions.set('default', styles.default);
styleOptions.set('slim', styles.slim);

const sizeOptions = new Map<sizeTypes, string>();
sizeOptions.set('normal', styles.normal);
sizeOptions.set('big', styles.big);

export const IconButton: FunctionComponent<Props> = props => {
    const { image } = iconOptions.find(x => x.icon === props.icon)!;
    const style = styleOptions.get(props.styleWeight || 'default');
    const size = sizeOptions.get(props.size || 'normal');
    const disabledStyle = props.disabled ? styles.disabled : '';

    const handleClick = () => {
        if (props.disabled) return;

        props.onClick();
    };

    return (
        <div
            className={`${styles.iconButton} ${style} ${size} ${disabledStyle} ${props.className}`}
            onClick={handleClick}>
            <img src={image} alt={props.icon}/>
        </div>
    );
};
