import React, { useCallback, useState } from 'react';
import styles from './change-password.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
    changePassword,
    closeChangePasswordDialog,
    getChangePasswordIsOpened,
    getPasswordMustBeChanged, LoginStateEnum
} from '../../../store/authSlice';
import { ChangePasswordDto } from '../../../types/ChangePasswordDto';
import { Dialog } from '../../utils/dialog';
import { Field } from '../../utils/field';
import { Button } from '../../utils/button';
import { OutlineButton } from '../../utils/outline-button';
import useKeyPress from "../../../hooks/useKeyPress";

export const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const changePasswordIsOpened = useSelector(getChangePasswordIsOpened);
    const mustChangePassword = useSelector(getPasswordMustBeChanged);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const isInvalidNewPassword = !!newPassword && !!currentPassword && newPassword === currentPassword;
    const isInvalidConfirmNewPassword = newPassword !== confirmNewPassword;

    const isUpdateDisabled = !currentPassword ||
        !newPassword ||
        !confirmNewPassword ||
        isInvalidNewPassword ||
        isInvalidConfirmNewPassword;

    const changePasswordForm: ChangePasswordDto = {
        currentPassword,
        newPassword,
        confirmNewPassword
    };

    const handleUpdateClick = useCallback(() => {
        if(isUpdateDisabled)
            return;

        dispatch(changePassword(changePasswordForm))
    }, [changePasswordForm, dispatch, isUpdateDisabled]);


    useKeyPress('Enter', handleUpdateClick, []);

    const closeDialog = mustChangePassword ? undefined : () =>
        dispatch(closeChangePasswordDialog());

    return (
        <>
            <Dialog
                isOpen={mustChangePassword || changePasswordIsOpened}
                onClose={closeDialog}
                title="Change Password"
                className={styles.changePassword}
            >
                <Field
                    label="Current Password"
                    placeholder="********"
                    obscureText={true}
                    onChange={setCurrentPassword}
                    value={currentPassword}
                />
                <Field
                    label="New Password"
                    placeholder="********"
                    onChange={setNewPassword}
                    value={newPassword}
                    obscureText={true}
                />
                {isInvalidNewPassword && (
                    <span className={styles.error}>Current and new passwords must be different!</span>
                )}
                <Field
                    label="Confirm New Password"
                    placeholder="********"
                    onChange={setConfirmNewPassword}
                    value={confirmNewPassword}
                    obscureText={true}
                />

                <div className={styles.actions}>
                    {!!closeDialog && (
                        <OutlineButton
                            label="Cancel"
                            onClick={closeDialog} />
                    )}
                    <Button
                        label="Update"
                        onClick={handleUpdateClick}
                        disabled={isUpdateDisabled} />
                </div>
            </Dialog>
        </>
    );
};
