import React, { ChangeEvent, FunctionComponent, useCallback, useRef } from 'react';
import { Icon } from '../icon';
import AddPhotoIcon from '../../../assets/images/icons/add-photo.svg';
import styles from './add-image-button.module.scss';
import { OutlineButton } from '../outline-button';

interface Prop {
    onChosen: (lst: string[]) => void;
}

export const AddImageButton: FunctionComponent<Prop> = props => {
    const handleSelected = useCallback(async (el: ChangeEvent<HTMLInputElement>) => {
        const files = el.target.files;
        if (!files)
            return;

        const getAsBase64 = (file: Blob): Promise<string> =>
            new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(file);
            });

        const promises = Array.from(files).map(getAsBase64);
        const lst = await Promise.all(promises);

        props.onChosen(lst.slice());
    }, [props.onChosen]);

    const inputElement = useRef<HTMLInputElement>(null);
    const handleOpenClick = useCallback(() => inputElement.current?.click(), [inputElement]);

    return (
        <>
            <input
                ref={inputElement}
                type="file"
                multiple={true}
                accept="image/*"
                name=""
                onChange={handleSelected}
                style={{ display: 'none' }}
            />
            <OutlineButton
                label="Adicionar imagem"
                className={styles.addImageButton}
                prefix={<Icon icon={AddPhotoIcon} className={styles.icon} />}
                onClick={handleOpenClick}
            />
        </>
    );
};
