import { getUrlGenerator } from "../utils";

const baseUrl = process.env.REACT_APP_STORAGE_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class StorageService {
    public static getUrl = (id: string): string =>
        generateUrl('file', id);
}
