import React, { FunctionComponent, useEffect, useState } from 'react';
import { BackofficeBasicUser, BackofficeUser } from '../../../types/BackofficeUser';
import { useAppSelector } from '../../../hooks';
import { getOperatorIsLoading } from '../../../store/operatorSlice';
import { Field } from '../../utils/field';
import { FieldPhoneNumber } from '../../utils/field-phone-number';
import styles from './base-form-operator.module.scss';
import { Button } from '../../utils/button';

interface Prop {
    value?: BackofficeUser;
    labelAction: string;
    onAction: (obj: BackofficeBasicUser) => void;
}

export const BaseFormOperator: FunctionComponent<Prop> = props => {
    const isLoading = useAppSelector(getOperatorIsLoading);

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleAction = () => {
        const payload: BackofficeBasicUser = {
            name,
            phoneNumber: `55${phoneNumber}`
        };

        props.onAction(payload);
    };

    useEffect(() => {
        if (!props.value)
            return;

        const val = props.value;
        setName(val.name);
        setPhoneNumber(val.phoneNumber.slice(-11));
    }, [props.value]);

    const didChange = name !== props.value?.name || phoneNumber !== props.value?.phoneNumber;

    const isDisabled = isLoading || !didChange || !name || !phoneNumber;

    return (
        <div className={styles.baseFormOperator}>
            <Field label="Nome" placeholder="Jhon stuart" value={name} onChange={setName} />
            <FieldPhoneNumber value={phoneNumber} onChange={setPhoneNumber} />

            <div className={styles.actions}>
                <Button
                    isLoading={isLoading}
                    label={props.labelAction}
                    disabled={isDisabled}
                    onClick={handleAction} />
            </div>
        </div>
    );

};
