import { FunctionComponent, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    getConversations,
    getMessages, selectedPhoneNumber,
    selectPhoneNumber,
    sendMessage
} from "../../store/chatSlice";
import { Field } from "../utils/field";
import { Conversation, SendMessageDto } from "../../types/Chat";
import styles from './chat.module.scss';
import { IconButton } from "../utils/icon-button";


export const Chat: FunctionComponent = () => {
        const dispatch = useAppDispatch();

        const [text, setText] = useState('');

        const messages = useAppSelector(getMessages);
        const conversations = useAppSelector(getConversations);
        const phoneNumberSelected = useAppSelector(selectedPhoneNumber);

        const handleAction = useCallback(() => {
            const phoneNumber = phoneNumberSelected
            const payload: SendMessageDto = {
                text,
                phoneNumber
            }
            dispatch(sendMessage(payload))
        }, [text, dispatch]);

        const handleOnChange = useCallback((text: string) => {
            setText(text)
        }, [text])

        const selectConversation = useCallback((conversation: Conversation) => () => {
            dispatch(selectPhoneNumber(conversation.phoneNumber))
        }, [dispatch])


        const conversationSelectStyle = (phoneNumber: string): string => {
            return phoneNumber === phoneNumberSelected ? styles.selected : "";
        }

        return (
            <div className={styles.chat}>

                <div className={styles.conversations}>
                    {conversations.map(x => (
                        <div key={Math.random()} className={`${conversationSelectStyle(x.phoneNumber)}`}
                             onClick={selectConversation(x)}>
                            <span className={styles.name}>{x.name}</span>
                            <span className={styles.lastMessage}>{x.lastMessage}</span>
                        </div>
                    ))}
                </div>

                <div className={styles.messages}>
                    <div className={styles.content}>
                        {messages.map(x => (
                            <div key={x.id} className={x.flow === 'inbound' ? styles.inbound : styles.outbound}>
                                <span>{x.text}</span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.actions}>
                        <Field label='Enviar whatsapp'
                               placeholder='Como podemos ajudar ?'
                               className={styles.field}
                               onChange={setText}
                        />
                        <IconButton icon={'send'} className={styles.icon} onClick={handleAction}/>
                    </div>
                </div>
            </div>

        );
    }
;