import DashboardIcon from '../assets/images/icons/dashboard.svg';
import { Dashboard } from '../components/Dashboard';
import TakeoutIcon from '../assets/images/icons/takeout.svg';
import { Products } from '../components/Products';
import { BackofficeUser, Role } from '../types/BackofficeUser';
import { AddProduct } from '../components/Products/AddProduct';
import { EditProduct } from '../components/Products/EditProduct';
import CouponIcon from '../assets/images/icons/coupon.svg';
import SettingsIcon from '../assets/images/icons/settings.svg';
import PeopleIcon from '../assets/images/icons/people.svg';
import EngineerIcon from '../assets/images/icons/engineering.svg';
import MapIcon from '../assets/images/icons/map.svg';
import ChatIcon from '../assets/images/icons/chat.svg'


import React from 'react';
import { Coupons } from '../components/Coupons';
import { CreateCoupon } from '../components/Coupons/CreateCoupon';
import { EditCoupon } from '../components/Coupons/EditCoupon';
import { Settings } from '../components/Settings';
import { Operators } from '../components/Operators';
import { EditOperator } from '../components/Operators/EditOperator';
import { AddOperator } from '../components/Operators/AddOperator';
import { Areas } from '../components/Areas';
import { Clients } from '../components/Clients/intex';
import { ClientDetails } from '../components/Clients/Details';
import { EditClient } from '../components/Clients/EditClient';

import ShopCartIcon from '../assets/images/icons/shopping-cart.svg';
import { Orders } from '../components/Orders';
import { OrderDetails } from '../components/Orders/Details';
import { Chat } from "../components/Chat";

export interface IRoute {
    path: string;
    Component: React.FunctionComponent;
    authorized?: Role[];
}

export interface MenuItem extends IRoute {
    icon: any;
    label: string;
}


const allRoutesWithMenu: MenuItem[] = [
    {
        icon: DashboardIcon,
        label: 'Dashboard',
        path: '/dashboard',
        Component: Dashboard,
    },
    {
        icon: ShopCartIcon,
        label: 'Pedidos',
        path: '/orders',
        Component: Orders,
        authorized: [Role.admin, Role.operator]
    },
    {
        icon: PeopleIcon,
        label: 'Clientes',
        path: '/clients',
        Component: Clients,
        authorized: [Role.admin]
    },
    {
        icon: TakeoutIcon,
        label: 'Produtos',
        path: '/products',
        Component: Products,
        authorized: [Role.admin, Role.operator]
    },
    {
        icon: CouponIcon,
        label: 'Cupons de Desconto',
        path: '/coupons',
        Component: Coupons,
        authorized: [Role.admin, Role.operator]
    },
    {
        icon: EngineerIcon,
        label: 'Operadores',
        path: '/operators',
        Component: Operators,
        authorized: [Role.admin]
    },
    {
        icon: MapIcon,
        label: 'Areas',
        path: '/Areas',
        Component: Areas,
        authorized: [Role.admin]
    },
    {
        icon: ChatIcon,
        label: 'Chat',
        path: '/chat',
        Component: Chat,
        authorized: [Role.admin, Role.operator]
    },
    {
        icon: SettingsIcon,
        label: 'Configurações',
        path: '/settings',
        Component: Settings,
        authorized: [Role.admin]
    },

];

const allRoutesWithoutMenu: IRoute[] = [
    {
        path: '/products/new',
        Component: AddProduct,
        authorized: [Role.admin, Role.operator]
    },
    {
        path: '/products/:id/edit',
        Component: EditProduct,
        authorized: [Role.admin, Role.operator]
    },
    {
        path: '/coupons/new',
        Component: CreateCoupon,
        authorized: [Role.admin, Role.operator]
    },
    {
        path: '/coupons/:id/edit',
        Component: EditCoupon,
        authorized: [Role.admin, Role.operator]
    },
    {
        path: '/operators/new',
        Component: AddOperator,
        authorized: [Role.admin]
    },
    {
        path: '/operators/:id/edit',
        Component: EditOperator,
        authorized: [Role.admin]
    },
    {
        path: '/clients/:id',
        Component: ClientDetails,
        authorized: [Role.admin]
    },
    {
        path: '/clients/:id/edit',
        Component: EditClient,
        authorized: [Role.admin]
    },
    {
        path: '/orders/:id',
        Component: OrderDetails,
        authorized: [Role.admin, Role.operator]
    }
];

export const getRoutesWithMenu = (user: BackofficeUser): MenuItem[] =>
    allRoutesWithMenu.filter(route =>
        !!route.authorized ? route.authorized.includes(user.role) : true);

const getRoutesWithoutMenu = (user: BackofficeUser): IRoute[] =>
    allRoutesWithoutMenu.filter(route => !!route.authorized ? route.authorized.includes(user.role) : true);

export const getRoutes = (user: BackofficeUser): IRoute[] =>
    [...getRoutesWithMenu(user), ...getRoutesWithoutMenu(user)];
