import { FunctionComponent, useCallback } from 'react';
import {
    DeliveryMethodEnum,
    Order,
    OrderProduct as OrderProductDto,
    StatusEnum,
    ToGoOrder
} from '../../../types/Order';
import { With } from '../../../types';
import styles from './operator-order-card.module.scss';
import { Label } from '../../utils/label';
import { Button } from '../../utils/button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { bumpStatus, getOrderIsLoading } from '../../../store/orderSlice';
import { formatPaymentInfo, formatPriceAmount, getTotalValue, phoneFormatter } from '../../../utils/formatters';

const OrderProduct: FunctionComponent<With<OrderProductDto>> = ({ data }) => {
    return (
        <div className={styles.orderProduct}>
            <span>{data.quantity}</span> x <span>{data.product.name}</span>
            {!!data.option && (<span> ({data.option})</span>)}
            {!!data.observation && <span className={styles.observation}>{data.observation}</span>}
        </div>
    );
};

export const OperatorOrderCard: FunctionComponent<With<Order>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const orderIsLoading = useAppSelector(getOrderIsLoading(data.id));

    const renderDeliverInfo = (order: ToGoOrder) => {
        const { complement, neighborhood, number, streetLine, city, postalCode, lat, lng } = order.address;
        return (
            <>
                <Label text="Taxa de entrega">{formatPriceAmount(order.area.deliveryFee)}</Label>
                <Label text="Endereço">
                    <span>
                        {streetLine} {number} <br />
                        {!!complement && (<>{complement}<br /></>)}
                        {neighborhood} <br />
                        {city} - {postalCode} <br />
                        <a
                            target="_blank"
                            rel="noreferrer nofollow"
                            href={`https://www.google.com/maps/@${lat},${lng},13z?entry=ttu`}>
                            Mapa
                        </a>
                    </span>
                </Label>
            </>
        );
    };

    const renderDeliverInHouseInfo = () =>
        <Label text="Forma de entrega">Balcão</Label>;

    const deliveryRender = {
        [DeliveryMethodEnum.delivery]: () => renderDeliverInfo(data as ToGoOrder),
        [DeliveryMethodEnum.pickup]: renderDeliverInHouseInfo
    }[data.deliveryMethod];

    const handleClick = useCallback(() => {
        dispatch(bumpStatus(data.id));
    }, [data.id, dispatch]);

    const labelAction = {
        [StatusEnum.inPreparation]: 'Pedido pronto',
        [StatusEnum.readyToDeliver]: {
            [DeliveryMethodEnum.pickup]: 'Entregue ao cliente',
            [DeliveryMethodEnum.delivery]: 'Entregue ao motoboy'
        }[data.deliveryMethod],
        [StatusEnum.inTransit]: 'Entregue ao cliente',
        [StatusEnum.pending]: '',
        [StatusEnum.delivered]: ''
    }[data.status];

    return (
        <div className={styles.operatorOrderCard}>
            <div className={styles.details}>
                <div className={styles.title}>
                    <h2>{data.client.name.split(' ')[0]}</h2>
                </div>

                <Label text="Data do pedido">{data.createdAt.toLocaleString()}</Label>

                <Label text="Produtos" className={styles.products}>
                    {data.products.map(product => <OrderProduct data={product} key={product.product.id} />)}
                </Label>

                <Label text="Nome do cliente">{data.client.name}</Label>

                <Label text="Telefone client">{phoneFormatter(data.client.phoneNumber)}</Label>

                {data.discountCoupon && (
                    <Label text="Cupom de desconto">{data.discountCoupon.key} ({formatPriceAmount(data.discountCoupon.discountValue)})</Label>
                )}

                {deliveryRender()}

                <Label text="Total">{getTotalValue(data)}</Label>

                <Label text="Status Pagamento">{formatPaymentInfo(data)}</Label>
                <Label text="Id do pagamento">{data.id}</Label>
            </div>
            {!!labelAction && (
                <div className={styles.actions}>
                    <Button
                        isLoading={orderIsLoading}
                        label={labelAction}
                        onClick={handleClick}
                    />
                </div>
            )}
        </div>
    );
};

