import styles from './areas.module.scss';
import MapArea from './Area';
import CheckIcon from '../../assets/images/icons/save.svg';
import { Card } from '../utils/card';
import { HeaderApp } from '../utils/header';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNewArea,
    getAreas,
    getSelectedArea,
    getSomeAreaWasEdited,
    removeArea,
    resetChanges,
    selectArea,
    unselectArea,
    updateAreas,
    updateSelectedArea
} from '../../store/areasSlice';
import { Field } from '../utils/field';
import { IconButton } from '../utils/icon-button';
import { Area } from '../../types/Area';
import { useAppDispatch } from '../../hooks';
import { FloatingActionButton } from '../utils/floating-action-button';
import { FieldNumber } from '../utils/field-number';
import { OutlineButton } from '../utils/outline-button';

const isInvalidArea = (area: Area): boolean => {
    return !area.name || !area.deliveryFee || area.polygon.length < 3;
};

const EditSelectedArea = () => {
    const dispatch = useDispatch();

    const selectedArea = useSelector(getSelectedArea);

    if (!selectedArea) {
        return null;
    }

    const updateName = (name: string) => dispatch(updateSelectedArea({ name }));
    const updateDeliveryFee = (deliveryFee: number) => dispatch(updateSelectedArea({ deliveryFee }));

    const btnDisable = isInvalidArea(selectedArea);

    return (
        <div className={`${styles.areaItem} ${styles.editedAreaItem}`}>
            <Field
                placeholder="Nova odessa"
                label="Localidade"
                value={selectedArea.name}
                onChange={updateName}
            />
            <FieldNumber
                label="Taxa de entrega"
                placeHolder="32.12"
                onChange={updateDeliveryFee}
                value={selectedArea.deliveryFee}
            />
            <IconButton icon="check" disabled={btnDisable} onClick={() => dispatch(unselectArea())} />
        </div>
    );
};

export const Areas = () => {
    const dispatch = useAppDispatch();

    const selectedArea = useSelector(getSelectedArea);

    const areas = useSelector(getAreas);
    const wasChanged = useSelector(getSomeAreaWasEdited);

    const handleSelection = useCallback((area: Area) =>
        dispatch(selectArea(area)), [areas]);

    const handleDeletion = useCallback((area: Area) =>
        dispatch(removeArea(area.id)), [areas]);

    const renderAreaItem = (area: Area) => {
        const isSelected = selectedArea?.id === area.id;
        if (isSelected)
            return <EditSelectedArea key={area.id} />;

        return (
            <div key={area.id} className={styles.areaItem}>
                <span onClick={() => handleSelection(area)}>{area.name || 'undefined'}</span>
                <span> {area.deliveryFee}</span>
                <IconButton icon="close" onClick={() => handleDeletion(area)} />
            </div>
        );
    };

    const btnDisable = areas.some(isInvalidArea) || !areas.length;

    return (
        <Card>
            <HeaderApp title="Areas">
                {wasChanged && (
                    <OutlineButton label="Descartar" onClick={() => dispatch(resetChanges())} />
                )}
                <IconButton icon="add" size="big" onClick={() => dispatch(addNewArea())} />
            </HeaderApp>
            <div className={styles.areas}>
                <MapArea />
                <div className={styles.list}>{areas.map(renderAreaItem)}</div>
                {wasChanged && !btnDisable && (
                    <FloatingActionButton icon={CheckIcon} onClick={() => dispatch(updateAreas())} />
                )}
            </div>
        </Card>
    );
};
