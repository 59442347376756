import { getUrlGenerator } from '../utils';
import { Order } from '../types/Order';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_ORDER_API;


const generateUrl = getUrlGenerator(baseUrl);

export default class OrderService {
    public static bumpStatus = (id: string): Promise<Order> =>
        Api.put(generateUrl('bump-status', id));
}
