import { HasLastUpdatedDate, HasUpdatedAt } from '../types';
import { apiResponseFormatter } from './index';

export const saveOnLocalStorage = <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const removeOnLocalStorage = (key: string) =>
    localStorage.removeItem(key);

export const retrieveFromLocalStorage = <T>(key: string, defaultVal?: T): T | undefined => {
    const stringPayload = localStorage.getItem(key);

    if (!stringPayload)
        return;

    try {
        const result: T = JSON.parse(stringPayload);
        return apiResponseFormatter(result);
    } catch (err) {
        return stringPayload as T;
    }
};

export const saveLastUpdatedDate = <T extends HasUpdatedAt>(key: string, lst: T[]): void => {
    if (!lst.length)
        throw new Error('List doesn\'t have items');

    const [lastUpdatedObj] = [...lst].sort((a, b) => b.updatedAt.getTime() > a.updatedAt.getTime() ? 1 : -1);
    saveOnLocalStorage<HasLastUpdatedDate>(key, { lastUpdatedDate: lastUpdatedObj.updatedAt });
};

export const retrieveLastUpdatedDateOrDefault = (key: string): HasLastUpdatedDate =>
    retrieveFromLocalStorage<HasLastUpdatedDate>(key)
    || { lastUpdatedDate: new Date(0) };
