import { CategoryEnum, CreateProductDto, Product, UpdateProductDto } from '../types/Product';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import ProductService from '../services/ProductService';
import { showAlert } from './notificationSlice';
import { Websocket } from '../services/Websocket';
import { Columns, downloadCsv } from '../utils/report';
import StorageService from '../services/StorageService';
import { formatAnnotation, formatCategory, formatPriceAmount, sortedCategories } from '../utils/formatters';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { BasePagination, getPaged } from '../utils/pagination';

const PRODUCTS_KEY = 'products';
const PRODUCTS_LAST_UPDATED_DATE = 'products_last_updated';

interface ProductState {
    lstProducts: Product[];
    productsIsLoading: string[];
    generalLoading: boolean;
    filteredCategories: CategoryEnum[];
}

const initialState: ProductState = {
    lstProducts: [],
    productsIsLoading: [],
    generalLoading: false,
    filteredCategories: [],
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        upsertProduct: (state, action: PayloadAction<Product>) => {
            state.lstProducts = upsertOnList(state.lstProducts, action.payload);
        },
        removeProduct: (state, action: PayloadAction<string>) => {
            state.lstProducts = state.lstProducts.filter(x => x.id !== action.payload);
        },
        updateProductList: (state, action: PayloadAction<Product[]>) => {
            state.lstProducts = action.payload;
        },
        setProductIsLoading: (state, action: PayloadAction<string>) => {
            state.productsIsLoading = [...state.productsIsLoading, action.payload];
        },
        removeProductIsLoading: (state, action: PayloadAction<string>) => {
            state.productsIsLoading = state.productsIsLoading.filter(x => x !== action.payload);
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.generalLoading = action.payload;
        },
        setFilteredCategories: (state, action: PayloadAction<CategoryEnum[]>) => {
            state.filteredCategories = action.payload;
        }
    }
});

const {
    upsertProduct,
    removeProduct,
    updateProductList,
    setProductIsLoading,
    setIsLoading,
    removeProductIsLoading
} = productSlice.actions;

export const { setFilteredCategories } = productSlice.actions;

export const createProduct = (dto: CreateProductDto): AppThunk<Promise<void>> => dispatch => {
    dispatch(setIsLoading(true));

    return ProductService.create(dto)
        .then(() => {
            dispatch(showAlert('Produto Criado'));
        }).finally(() => {
            dispatch(setIsLoading(false));
        });
};

export const toggleProductIsEnabled = (product: Product): AppThunk => dispatch => {
    dispatch(setProductIsLoading(product.id));

    return ProductService.toggleProductIsEnabled(product)
        .then(() => {
            dispatch(showAlert('Produto autalizado'));
        })
        .finally(() => {
            dispatch(removeProductIsLoading(product.id));
        });
};

export const updateProduct = (dto: UpdateProductDto): AppThunk<Promise<void>> => dispatch => {
    dispatch(setProductIsLoading(dto.id));

    return ProductService.update(dto)
        .then(() => {
            dispatch(showAlert('Produto autalizado'));
        })
        .finally(() => {
            dispatch(removeProductIsLoading(dto.id));
        });
};

export const deleteProduct = (product: Product): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setProductIsLoading(product.id));

    try {
        await ProductService.delete(product.id);
        dispatch(showAlert(`Produto "${product.name}" foi deletado`));
    } catch {
    }
    dispatch(removeProductIsLoading(product.id));
};

export const bumpSortIndex = (id: string): AppThunk<Promise<void>> => dispatch => {
    dispatch(setIsLoading(true));

    return ProductService.bumpSortIndex(id)
        .then(() => {
            dispatch(showAlert('Produto movido para cima'));
        })
        .finally(() => {
            dispatch(setIsLoading(false));
        });
};

export const downgradeSortIndex = (id: string): AppThunk<Promise<void>> => dispatch => {
    dispatch(setIsLoading(true));

    return ProductService.downgradeSortIndex(id)
        .then(() => {
            dispatch(showAlert('Produto movido para baixo'));
        })
        .finally(() => {
            dispatch(setIsLoading(false));
        });
};

export const downloadProductsList = (): AppThunk => (dispatch, getState) => {
    const columns: Columns<Product> = [
        { title: 'Disponibilidade', getValue: x => x.isEnabled ? 'Disponivel' : 'Desabilitado' },
        { title: 'Id', getValue: x => x.id },
        { title: 'Nome', getValue: x => x.name },
        { title: 'Descrição', getValue: x => x.description },
        { title: 'Categoria', getValue: x => formatCategory(x.category) },
        { title: 'Preço', getValue: x => formatPriceAmount(x.price) },
        { title: 'Imagem', getValue: x => StorageService.getUrl(x.idImage) },
        { title: 'Quantidade', getValue: x => x.quantity },
        { title: 'Opções', getValue: x => x.options?.join(', ') },
        { title: 'Anotações', getValue: x => x.annotations.map(formatAnnotation).join(', ') },
        { title: 'Data de criação', getValue: x => x.createdAt.toLocaleString() },
        { title: 'Data de Atualização', getValue: x => x.updatedAt.toLocaleString() },
    ];

    const list = getState().product.lstProducts;
    downloadCsv(list, columns, 'Produtos.csv');
};

export const cleanProductsStateAndStorage = (): AppThunk => (dispatch, getState) => {
    dispatch(updateProductList([]));
    removeOnLocalStorage(PRODUCTS_KEY);
    removeOnLocalStorage(PRODUCTS_LAST_UPDATED_DATE);
};

export const setupProductState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Product>('update-product', product => {
        dispatch(upsertProduct(product));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<{ id: string }>('delete-product', ({ id }) => {
        dispatch(removeProduct(id));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(PRODUCTS_LAST_UPDATED_DATE);
    Websocket.emitOnConnection('subscribe-products', payload);
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const products = getState().product.lstProducts;
    saveOnLocalStorage(PRODUCTS_KEY, products);
    saveLastUpdatedDate(PRODUCTS_LAST_UPDATED_DATE, products);
};

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const lst = retrieveFromLocalStorage<Product[]>(PRODUCTS_KEY);

    if (!lst)
        return;

    lst.forEach(product => dispatch(upsertProduct(product)));
};

export const getProductsTotal = (state: RootState): number => state.product.lstProducts.length;
export const getSortedAndFilterProducts = (page: BasePagination) => (state: RootState): Product[] => {
    const filters = state.product.filteredCategories;
    const lst = state.product.lstProducts
        .filter(x => !filters.length || filters.includes(x.category));

    const newList = lst.sort((a, b) => {
        if (a.category === b.category)
            return a.sortIndex - b.sortIndex;

        return sortedCategories.indexOf(a.category) - sortedCategories.indexOf(b.category);
    });

    return getPaged(page, newList);
};
export const getProduct = (id: string | undefined) => (state: RootState): Product | undefined =>
    state.product.lstProducts.find(x => x.id === id);

export const getProductGeneralIsLoading = (state: RootState): boolean => state.product.generalLoading;
export const getProductIsLoading = (id: string | undefined) => (state: RootState): boolean =>
    state.product.productsIsLoading.includes(id || '');

export const getIsLastFromCategory = (obj: Product) => (state: RootState): boolean => {
    const last = state.product.lstProducts.filter(x => x.category === obj.category)
        .sort((a, b) => b.sortIndex - a.sortIndex)[0];
    return last.id === obj.id;
};

export const getFilersCategories = (state: RootState): CategoryEnum[] => state.product.filteredCategories;
export default productSlice.reducer;
