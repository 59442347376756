import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { UpdateClientDto } from '../types/Client';

const baseUrl = process.env.REACT_APP_CLIENT_API!;
const url = getUrlGenerator(baseUrl);

export default class ClientService {
    public static update = (dto: UpdateClientDto): Promise<void> =>
        Api.put(url(), dto);
}
