import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { HeaderApp } from '../utils/header';
import { Card } from '../utils/card';
import { FormFieldWithHint } from '../utils/form-field-with-hint';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getSettings, getSettingsIsLoading, updateSettings } from '../../store/settingsSlice';
import { SpinLoading } from '../utils/spin-loading';
import { FieldNumber } from '../utils/field-number';
import { FieldPhoneNumber } from '../utils/field-phone-number';
import { generateOptions, isTheSameOpenedHours } from '../../utils';
import { allMessageProviders } from '../../utils/formatters';
import { SelectField } from '../utils/select-field';
import { MessageProvider, OpenedHours, Settings as SettingsDto } from '../../types/Settings';
import { Button } from '../utils/button';
import styles from './settings.module.scss';
import { OpenedHoursField } from './OpenedHours';

const messageProviders = generateOptions(allMessageProviders);

export const Settings: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const [minimumPurchaseValue, setMinimumPurchaseValue] = useState<number>(0);
    const [whatsapp, setWhatsapp] = useState<string>('');
    const [estimatedTimeToDeliverInMinutes, setEstimatedTimeToDeliverInMinutes] = useState<number>(0);
    const [messageProvider, setMessageProvider] = useState<MessageProvider>(MessageProvider.comtele);
    const [openedHours, setOpenedHours] = useState<OpenedHours[]>();

    const settings = useAppSelector(getSettings);
    const isLoading = useAppSelector(getSettingsIsLoading);

    useEffect(() => {
        if (!settings)
            return;

        setMinimumPurchaseValue(settings.minimumPurchaseValue);
        setWhatsapp(settings.contacts.whatsapp.slice(-11));
        setEstimatedTimeToDeliverInMinutes(settings.estimatedTimeToDeliverInMinutes);
        setMessageProvider(settings.messageProvider);
        setOpenedHours(settings.openedHours);
    }, [settings]);

    const handleAction = useCallback(() => {
        const payload: SettingsDto = {
            minimumPurchaseValue,
            contacts: {
                whatsapp: `55${whatsapp}`
            },
            estimatedTimeToDeliverInMinutes,
            messageProvider,
            openedHours: openedHours!
        };

        dispatch(updateSettings(payload));
    }, [dispatch, estimatedTimeToDeliverInMinutes, messageProvider, minimumPurchaseValue, openedHours, whatsapp]);

    const didChange = minimumPurchaseValue !== settings?.minimumPurchaseValue
        || whatsapp !== settings?.contacts.whatsapp.replace(/^55/g, '')
        || estimatedTimeToDeliverInMinutes !== settings?.estimatedTimeToDeliverInMinutes
        || messageProvider !== settings?.messageProvider
        || !isTheSameOpenedHours(openedHours, settings?.openedHours);

    const isDisabled = !didChange ||
        !minimumPurchaseValue ||
        !whatsapp ||
        !estimatedTimeToDeliverInMinutes ||
        !messageProvider ||
        !openedHours;

    if (!settings)
        return <Card><SpinLoading /></Card>;

    return (
        <Card className={styles.settings}>
            <HeaderApp title="Configurações" />
            <FormFieldWithHint
                label="Valor minimo de compra"
                hint="Validação para o menor valor aceito para compras. Aqui não é levado em consideração o valor do frete">
                <FieldNumber
                    label="Valor minimo de compra"
                    onChange={setMinimumPurchaseValue}
                    value={minimumPurchaseValue}
                    placeHolder="10.32" />
            </FormFieldWithHint>

            <FormFieldWithHint
                label="Whatsapp"
                hint="Aqui é o numero do whatsapp informado no site do gordo para atendimento ao cliente">
                <FieldPhoneNumber
                    label="Whatsapp"
                    onChange={setWhatsapp}
                    value={whatsapp} />
            </FormFieldWithHint>

            <FormFieldWithHint
                label="Tempo estimado de entrega"
                hint="Tempo estimado de entrega em minutos. Aqui não importa nem a demanda do restaurante ou a distancia para entrega.">
                <FieldNumber
                    onChange={setEstimatedTimeToDeliverInMinutes}
                    value={estimatedTimeToDeliverInMinutes}
                    label="Tempo de entrega"
                    placeHolder="14" />
            </FormFieldWithHint>

            <FormFieldWithHint
                label="Enviador de mensagens"
                hint="Selecione o provedor de mensagens que será utilizado para enviar mensagens para os clientes, admins e operadores">
                <SelectField
                    label="Mensageiro"
                    options={messageProviders}
                    onChange={setMessageProvider}
                    value={messageProvider} />
            </FormFieldWithHint>

            <OpenedHoursField onChange={setOpenedHours} value={openedHours} />

            <div className={styles.actions}>
                <Button label="Salvar" onClick={handleAction} isLoading={isLoading} disabled={isDisabled} />
            </div>
        </Card>
    );
};
