import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { Api } from '../services/Api';
import { cleanAuthStorageAndLogoff } from './authSlice';
import { ConfirmationParams } from '../types';
import { cleanProductsStateAndStorage } from './productSlice';
import { cleanOrderStateAndStorage } from './orderSlice';
import { cleanNotificationState } from './notificationSlice';
import { cleanCouponStateAndStorage } from './couponsSlice';
import { cleanClientStateAndStorage } from './clientSlice';
import { cleanOperatorStateAndStorage } from './operatorSlice';
import { cleanSettingsStateAndStorage } from './settingsSlice';
import { cleanAreasStateAndStorage } from './areasSlice';

interface AppState {
    confirm: ConfirmationParams | null;
    hasConnection: boolean;
    aboutSectionIsOpened: boolean;
}

const initialState: AppState = {
    confirm: null,
    hasConnection: false,
    aboutSectionIsOpened: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setHasConnection: (state, action: PayloadAction<boolean>) => {
            state.hasConnection = action.payload;
        },
        setAboutSectionIsOpened: (state, action: PayloadAction<boolean>) => {
            state.aboutSectionIsOpened = action.payload;
        },
        openConfirmation: (state, action: PayloadAction<ConfirmationParams>) => {
            state.confirm = action.payload;
        },
        closeConfirmation: state => {
            state.confirm = null;
        },
        resetState: () => initialState,
    }
});

export const {
    setHasConnection,
    setAboutSectionIsOpened,
    openConfirmation,
    closeConfirmation,
    resetState
} = appSlice.actions;


export const getAboutSectionIsOpened = (state: RootState): boolean => state.app.aboutSectionIsOpened;
export const getConfirmation = (state: RootState): ConfirmationParams | null => state.app.confirm;


export const setupAppState = (): AppThunk => async dispatch => {
    const verifyHasConnection = () =>
        Api.hasConnectionToServer()
            .then(conn => dispatch(setHasConnection(conn)));

    verifyHasConnection()
        .then(() => setInterval(verifyHasConnection, 5 * 1000));
};

export const cleanLocalStorageAndLogoff = (): AppThunk<Promise<void>> => async dispatch => {
    dispatch(resetState());
    dispatch(cleanProductsStateAndStorage());
    dispatch(cleanSettingsStateAndStorage());
    dispatch(cleanClientStateAndStorage());
    dispatch(cleanOperatorStateAndStorage());
    dispatch(cleanCouponStateAndStorage());
    dispatch(cleanOrderStateAndStorage());
    dispatch(cleanNotificationState());
    dispatch(cleanAreasStateAndStorage());
    dispatch(cleanAuthStorageAndLogoff());
};

export default appSlice.reducer;
