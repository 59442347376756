import styles from './button.module.scss';
import React, { ReactElement, useCallback } from 'react';
import { SpinLoading } from '../spin-loading';
import { VoidCallback } from '../../../utils';

interface Props {
    prefix?: ReactElement;
    className?: string;
    label: string;
    onClick: VoidCallback;
    disabled?: boolean;
    isLoading?: boolean;
}

export const Button: React.FC<Props> = (props) => {
    const handleClick = useCallback(() => {
        if (props.disabled || props.isLoading) return;

        props.onClick();
    }, [props]);

    let style = styles.button;
    if (props.className) style += ` ${props.className}`;
    if (props.disabled || props.isLoading) style += ` ${styles.disabled}`;

    return (
        <div className={style} onClick={handleClick}>
            {!!props.prefix && (props.prefix)}
            {!!props.isLoading && (
                <SpinLoading />
            )}

            {!props.isLoading && props.label}
        </div>
    );
};
