import { FunctionComponent, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { BasePagination, basicPagination } from '../../utils/pagination';
import { downloadClientsList, getClients, getTotalClients } from '../../store/clientSlice';
import styles from './clients.module.scss';
import { DropdownItem, Dropdowns } from '../utils/dropdowns';
import { With } from '../../types';
import EditIcon from '../../assets/images/icons/edit.svg';
import { Client } from '../../types/Client';
import { Card } from '../utils/card';
import { IconButton } from '../utils/icon-button';
import { HeaderApp } from '../utils/header';
import { Paginator } from '../utils/paginator';
import EyeIcon from '../../assets/images/icons/eye.svg';

export const Clients: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const lst = useAppSelector(getClients(pagination));
    const total = useAppSelector(getTotalClients);

    const downloadReport = useCallback(() => {
        dispatch(downloadClientsList());
    }, [dispatch]);

    const openClient = useCallback((client: Client) => () => {
        navigate(`/clients/${client.id}`);
    }, [navigate]);

    const renderTable = () => {
        if (!total)
            return <div>Nenhum cliente encontrado</div>;

        return (
            <div className={styles.tableClients}>
                <table>
                    <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Telefone</th>
                        <th>Email</th>
                        <th>Qtd endereços</th>
                        <th>Qtd Cartões</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {lst.map(x => (
                        <tr key={x.id}>
                            <td data-title="Nome" onClick={openClient(x)}>{x.name}</td>
                            <td data-title="Telefone" onClick={openClient(x)}>{x.phoneNumber}</td>
                            <td data-title="Email" onClick={openClient(x)}>{x.email}</td>
                            <td data-title="Qtd endereços" onClick={openClient(x)}>{x.addresses.length}</td>
                            <td data-title="Qtd Cartões" onClick={openClient(x)}>{x.paymentCards.length}</td>
                            <td>
                                <DropdownClient data={x} />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <Card className={styles.clients}>
            <HeaderApp title="Clientes">
                <IconButton icon="download" onClick={downloadReport} />
            </HeaderApp>
            {renderTable()}
            <Paginator pagination={pagination} totals={total} setPage={setPagination} />
        </Card>
    );
};

const DropdownClient: FunctionComponent<With<Client>> = ({ data }) => {
    const navigate = useNavigate();

    const { id } = data;
    const editClientOption: DropdownItem = {
        label: 'Editar',
        icon: EditIcon,
        onClick: () => navigate(`/clients/${id}/edit`)
    };

    const removeClientOption: DropdownItem = {
        label: 'Detalhes',
        icon: EyeIcon,
        onClick: () => navigate(`/clients/${id}`)
    };

    return <Dropdowns options={[editClientOption, removeClientOption]} />;
};
