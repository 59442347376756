import { useCallback, useEffect, useState } from 'react';
import { SelectFieldOption } from '../../../types';
import styles from './select-multiple-field.module.scss';

interface Props<T> {
    options: SelectFieldOption<T>[];
    onChange: (obj: T[]) => void;
    value: T[];
    label?: string;
}

export const SelectMultipleField = <T extends any>({ options, onChange, value, label }: Props<T>) => {
    const [selected, setSelected] = useState<T[]>([]);

    useEffect(() => {
        if (!value)
            return;

        setSelected(value);
    }, [value]);


    const toggleFromSelection = useCallback((obj: T) => {
        const getNewList = () => {
            if (selected.includes(obj))
                return selected.filter(x => x !== obj);

            return [...selected, obj];

        };

        const newList = getNewList();

        setSelected(newList);
        onChange(newList);
    }, [onChange, selected]);

    return (
        <div className={styles.selectMultipleField}>
            <div className={styles.label}>
                <span>{label}</span>
            </div>
            <div className={styles.options}>
                {options.map((x, i) => {
                    const isSelected = selected.includes(x.value);
                    const style = isSelected ? styles.active : '';

                    const onClick = () => toggleFromSelection(x.value);

                    return (
                        <span key={i} onClick={onClick} className={style}>{x.label}</span>
                    );
                })}
            </div>
        </div>
    );

};
