import React, { FunctionComponent, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    cleanNewlyCreatedUser,
    deleteOperator,
    downloadOperatorsList,
    getOperatorNewlyCreated,
    getOperators,
    getOperatorsTotal
} from '../../store/operatorSlice';
import { useNavigate } from 'react-router-dom';
import { BasePagination, basicPagination } from '../../utils/pagination';
import styles from './operators.module.scss';
import { BackofficeUser } from '../../types/BackofficeUser';
import { With } from '../../types';
import { DropdownItem, Dropdowns } from '../utils/dropdowns';
import EditIcon from '../../assets/images/icons/edit.svg';
import TrashIcon from '../../assets/images/icons/delete.svg';
import { openConfirmation } from '../../store/appSlice';
import { Paginator } from '../utils/paginator';
import { FloatingActionButton } from '../utils/floating-action-button';
import { HeaderApp } from '../utils/header';
import { Card } from '../utils/card';
import AddIcon from '../../assets/images/icons/add.svg';
import { IconButton } from '../utils/icon-button';
import { phoneFormatter } from '../../utils/formatters';

export const Operators: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const lst = useAppSelector(getOperators(pagination));
    const total = useAppSelector(getOperatorsTotal);

    const newlyCreatedOperator = useAppSelector(getOperatorNewlyCreated);

    const downloadReport = useCallback(() => {
        dispatch(downloadOperatorsList());
    }, [dispatch]);

    const openOperator = useCallback((data: BackofficeUser) => () => {
        navigate(`/operators/${data.id}/edit`);
    }, [navigate]);

    const cleanNewlyCreatedOperator = useCallback(() => {
        dispatch(cleanNewlyCreatedUser());
    }, [dispatch]);

    const renderTable = () => {
        if (!total)
            return <div>Nenhum operador encontrado</div>;

        return (
            <div className={styles.tableOperators}>
                <table>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Telefone</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {lst.map(x => (
                        <tr key={x.id}>
                            <td data-title="Id" onClick={openOperator(x)}>{x.id}</td>
                            <td data-title="Nome" onClick={openOperator(x)}>{x.name}</td>
                            <td data-title="Telefone" onClick={openOperator(x)}>{phoneFormatter(x.phoneNumber)}</td>
                            <td>
                                <DropdownOperator data={x} />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <Card className={styles.operators}>
            <HeaderApp title="Operators">
                <IconButton icon="download" onClick={downloadReport} />
            </HeaderApp>
            <FloatingActionButton
                icon={AddIcon}
                onClick={() => navigate('/operators/new')}
            />

            {newlyCreatedOperator?.password && (
                <div className={styles.passwordAlert}>
                    <p>Senha do {newlyCreatedOperator.name}: {newlyCreatedOperator.password}</p>
                    <IconButton icon="close" onClick={cleanNewlyCreatedOperator} />
                </div>
            )}
            {renderTable()}
            <Paginator pagination={pagination} totals={total} setPage={setPagination} />
        </Card>
    );
};

const DropdownOperator: FunctionComponent<With<BackofficeUser>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id } = data;

    const editOperatorOption: DropdownItem = {
        icon: EditIcon,
        onClick: () => navigate(`/operators/${id}/edit`),
        label: 'Editar'
    };

    const removeOperator: DropdownItem = {
        icon: TrashIcon,
        onClick: () => dispatch(openConfirmation({
            title: `Remove ${data.name}`,
            text: `Você confirma a deleção do operador ${data.name}?`,
            onConfirm: () => dispatch(deleteOperator(data.id)),
        })),
        label: 'Remover '
    };

    return <Dropdowns options={[editOperatorOption, removeOperator]} />;
};
