import { Action, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import notification from './notificationSlice';
import app, { setupAppState } from './appSlice';
import auth, { loginFromStorage } from './authSlice';
import order, { setupOrderState } from './orderSlice';
import product, { setupProductState } from './productSlice';
import area, { setupAreasState } from './areasSlice';
import { Api } from '../services/Api';
import { delay } from '../utils';
import coupons, { setupCouponState } from './couponsSlice';
import client, { setupClientState } from './clientSlice';
import operator, { setupOperatorState } from './operatorSlice';
import settings, { setupSettingsState } from './settingsSlice';
import chat, {setupChatState} from "./chatSlice";

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        app,
        auth,
        notification,
        order,
        product,
        coupons,
        client,
        operator,
        settings,
        area,
        chat
    },
});

export type AppThunkDispatcher = ThunkDispatch<RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export const setupApplication = async (): Promise<void> => {
    Api.setDispatcher(store.dispatch);

    store.dispatch(setupAppState());
    await delay(400);

    store.dispatch(loginFromStorage());
    store.dispatch(setupSettingsState());
    store.dispatch(setupClientState());
    store.dispatch(setupOperatorState());
    store.dispatch(setupCouponState());
    store.dispatch(setupProductState());
    store.dispatch(setupOrderState());
    store.dispatch(setupAreasState());
    store.dispatch(setupChatState());
};
