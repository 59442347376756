export interface BasePagination {
    pageIndex: number;
    pageSize: number;
}

export const getPaged = <T>(page: BasePagination, lst: T[]): T[] => {
    if (!lst.length)
        return [];

    const start = page.pageSize * page.pageIndex;
    const end = page.pageSize * (page.pageIndex + 1);

    return lst.slice(start, end);
};

export const basicPagination: BasePagination = {
    pageIndex: 0,
    pageSize: 10,
};
