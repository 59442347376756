import styles from './dashboard.module.scss';
import { FunctionComponent } from 'react';
import { useAppSelector } from '../../hooks';
import { getSortedOrders } from '../../store/orderSlice';
import { OperatorOrderCard } from './OperatorOrderCard';
import { StatusEnum } from '../../types/Order';
import { Role } from '../../types/BackofficeUser';
import { KitchenOrderCard } from './KitchenOrderCard';
import { getLoggedUser } from '../../store/authSlice';
import { getRoutesWithMenu } from '../../utils/routes';
import { useLocation, useNavigate } from 'react-router-dom';

const statusToShow: StatusEnum[] = [
    StatusEnum.inPreparation,
    StatusEnum.readyToDeliver,
    StatusEnum.inTransit,
];
export const Dashboard: FunctionComponent = () => {
    const loggedUser = useAppSelector(getLoggedUser)!;
    const location = useLocation();
    const navigate = useNavigate();
    const orders = useAppSelector(getSortedOrders(statusToShow));

    const OrderCard = {
        [Role.admin]: OperatorOrderCard,
        [Role.operator]: OperatorOrderCard,
        [Role.kitchen]: KitchenOrderCard
    }[loggedUser!.role]!;

    const routes = getRoutesWithMenu(loggedUser)
        .filter(x => x.path !== location.pathname);

    return (
        <div className={styles.dashboard}>
            <div className={styles.menu}>
                {routes.map(menu => (
                    <div className={styles.item} key={menu.path} onClick={() => navigate(menu.path)}>
                        <div className="icon">
                            <img src={menu.icon} />
                        </div>
                        <span>{menu.label}</span>
                    </div>
                ))}
            </div>
            <div className={styles.orders}>
                {orders.map(order => (<OrderCard data={order} key={order.id} />))}
            </div>
        </div>
    );
};
