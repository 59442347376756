import { FunctionComponent, useCallback, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { getClient } from '../../../store/clientSlice';
import { Card } from '../../utils/card';
import { HeaderApp } from '../../utils/header';
import { Label } from '../../utils/label';
import styles from './client-details.module.scss';
import { IconButton } from '../../utils/icon-button';
import { TabOption, Tabs } from '../../utils/tabs';
import { With } from '../../../types';
import { getOrdersByClient } from '../../../store/orderSlice';
import { formatDeliveryInfo, formatPaymentInfo, formatStatus, getTotalValue } from '../../../utils/formatters';
import { Order } from '../../../types/Order';
import { Client } from '../../../types/Client';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';

enum ClientTabEnum {
    Orders = 'orders',
    Addresses = 'addresses',
    PaymentCards = 'paymentCards'
}

export const ClientDetails: FunctionComponent = () => {
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState<ClientTabEnum>(ClientTabEnum.Orders);

    const { id } = useParams();
    const client = useAppSelector(getClient(id));

    const editClient = useCallback(() => {
        navigate(`/clients/${id}/edit`);
    }, []);

    if (!client)
        return <div>Cliente não encontrado</div>;

    const tabOptions: TabOption[] = [
        {
            label: 'Pedidos',
            onClick: () => setActiveTab(ClientTabEnum.Orders),
            active: activeTab === ClientTabEnum.Orders,
        },
        {
            label: 'Endereços',
            onClick: () => setActiveTab(ClientTabEnum.Addresses),
            active: activeTab === ClientTabEnum.Addresses,
        },
        {
            label: 'Cartões',
            onClick: () => setActiveTab(ClientTabEnum.PaymentCards),
            active: activeTab === ClientTabEnum.PaymentCards,
        },
    ];

    const SelectedTab = {
        [ClientTabEnum.Orders]: TableOrders,
        [ClientTabEnum.Addresses]: TableAddresses,
        [ClientTabEnum.PaymentCards]: TableCards,
    }[activeTab];

    const breadcrumbs: BreadcrumbItem[] = [
        { label: 'Clientes', link: '/clients' },
        { label: client.name }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumbs} />
            <Card className={styles.clientDetails}>
                <HeaderApp title={client.name}>
                    <IconButton icon="edit" onClick={editClient} />
                </HeaderApp>

                <div className={styles.info}>
                    <Label text="Nome" content={client.name} />
                    <Label text="Telefone" content={client.phoneNumber} />
                    <Label text="Email" content={client.email} />
                    <Label text="Cliente recebe emails" content={client.receiveEmails ? 'Sim' : 'Não'} />
                    <Label text="Criado em" content={client.createdAt.toLocaleString()} />
                    <Label text="Atualizado em" content={client.updatedAt.toLocaleString()} />
                </div>
                <Tabs options={tabOptions} />
                <div className={styles.tabContent}>
                    <SelectedTab data={client} />
                </div>
            </Card>
        </>
    );
};

const TableOrders: FunctionComponent<With<Client>> = ({ data }) => {
    const orders = useAppSelector(getOrdersByClient(data.id));

    const navigate = useNavigate();

    const openOrder = useCallback((obj: Order) => {
        navigate(`/orders/${obj.id}`);
    }, [navigate]);

    if (!orders.length)
        return <div>Ainda não tem pedidos</div>;

    return (
        <table className={styles.tableOrders}>
            <thead>
            <tr>
                <th>Qtd Itens</th>
                <th>Status</th>
                <th>Pagamento</th>
                <th>Entrega</th>
                <th>Valor</th>
                <th>Pedido</th>
            </tr>
            </thead>
            <tbody>
            {orders.map(x => (
                <tr key={x.id} onClick={openOrder.bind(this, x)}>
                    <td data-title="Qtd Itens">{x.products.reduce((agg, b) => b.quantity + agg, 0)}</td>
                    <td data-title="Status">{formatStatus(x)}</td>
                    <td data-title="Pagamento">{formatPaymentInfo(x)}</td>
                    <td data-title="Entrega">{formatDeliveryInfo(x)}</td>
                    <td data-title="Valor">{getTotalValue(x)}</td>
                    <td data-title="Data">{x.createdAt.toLocaleString()}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

const TableAddresses: FunctionComponent<With<Client>> = ({ data }) => {
    if (!data.addresses.length)
        return <div>Ainda não tem endereços</div>;

    return (
        <table>
            <thead>
            <tr>
                <th>Endereço</th>
                <th>Complemento</th>
                <th>Bairro</th>
                <th>Cidade</th>
                <th>Estado</th>
                <th>CEP</th>
            </tr>
            </thead>
            <tbody>
            {data.addresses.map((x, key) => (
                <tr key={key}>
                    <td>{x.info.streetLine}</td>
                    <td>{x.info.complement}</td>
                    <td>{x.info.neighborhood}</td>
                    <td>{x.info.city}</td>
                    <td>{x.info.state}</td>
                    <td>{x.info.postalCode}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

const TableCards: FunctionComponent<With<Client>> = ({ data }) => {
    if (!data.paymentCards.length)
        return <div>Ainda não tem cartões de pagamento</div>;

    return (
        <table>
            <thead>
            <tr>
                <th>Nome</th>
                <th>Documento</th>
                <th>Endereço</th>
                <th>Número</th>
                <th>CCV</th>
                <th>Validade</th>
                <th>Bandeira</th>
            </tr>
            </thead>
            <tbody>
            {data.paymentCards.map((x, key) => (
                <tr key={key}>
                    <td>{x.name}</td>
                    <td>{x.document}</td>
                    <td>{x.address.postalCode}</td>
                    <td>{x.number}</td>
                    <td>{x.ccv}</td>
                    <td>{x.expiration}</td>
                    <td>{x.brand}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};
