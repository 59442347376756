import { AppThunk, AppThunkDispatcher, RootState } from "./index";
import {
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from "../utils/storage";
import { Conversation, Message, SendMessageDto } from "../types/Chat";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { delay, upsertOnList } from "../utils";
import { Websocket } from "../services/Websocket";
import ChatService from "../services/ChatService";

const MESSAGES_KEY = 'messages';
const MESSAGES_LAST_UPDATED_DATE = 'messages_last_updated'

interface ChatState {
    lstMessages: Message[];
    chatIsLoading: boolean;
    selectedPhoneNumber: string
}

const initialState: ChatState = {
    lstMessages: [],
    chatIsLoading: false,
    selectedPhoneNumber: ''
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        upsertMessages: (state, action: PayloadAction<Message>) => {
            state.lstMessages = upsertOnList(state.lstMessages, action.payload)
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.chatIsLoading = action.payload
        },
        selectPhoneNumber: (state, action: PayloadAction<string>) => {
            state.selectedPhoneNumber = action.payload
        }
    }
});

export const {
    upsertMessages,
    setIsLoading,
    selectPhoneNumber
} = chatSlice.actions;

export const sendMessage = (dto: SendMessageDto): AppThunk => dispatch => {
    dispatch(setIsLoading(true));

    return ChatService.sendMessage(dto)
        .finally(() => {
            dispatch(setIsLoading(false))
        });
}

export const setupChatState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Message>('chat-new-message', message => {
        dispatch(upsertMessages(message));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(MESSAGES_LAST_UPDATED_DATE);
    Websocket.emitOnConnection('subscribe-chat-messages', payload);
};

const updateOnStorage = async (getState: () => RootState) => {
    await delay(100);

    const messages = getState().chat.lstMessages;
    saveOnLocalStorage(MESSAGES_KEY, messages);
    saveLastUpdatedDate(MESSAGES_LAST_UPDATED_DATE, messages);
}

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const lst = retrieveFromLocalStorage<Message[]>(MESSAGES_KEY);

    if (!lst)
        return

    lst.forEach(message => dispatch(upsertMessages(message)));
};

export const getMessages = (state: RootState): Message[] => {
    const phoneNumber = state.chat.selectedPhoneNumber;
    if (!phoneNumber)
        return []

    return state.chat.lstMessages.filter(x => x.from == phoneNumber || x.to === phoneNumber);
};

export const selectedPhoneNumber = (state: RootState): string  => state.chat.selectedPhoneNumber


export const getConversations = (state: RootState): Conversation[] => {
    const lstMessages = [...state.chat.lstMessages];
    const getName = (phoneNumber: string): string =>
        lstMessages.find(x => x.from === phoneNumber)!.name;

    return lstMessages
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .reduce((agg: Conversation[], item) => {
            const phoneNumber = item.flow === "inbound"? item.from: item.to;

            const existentConversation = agg.find(x => x.phoneNumber === phoneNumber)
            if (!!existentConversation)
                return agg

            const newConversation: Conversation = {
                name: getName(phoneNumber),
                phoneNumber,
                lastMessage: item.text
            }
            return [...agg, newConversation]
        }, [])
}

export default chatSlice.reducer;