import { FunctionComponent } from "react";
import styles from './icon.module.scss'

interface Prop{
    icon: string;
    alt?: string;
    className?: string;
}

export const Icon: FunctionComponent<Prop> = ({icon, alt, className}) => {
    return <img src={icon} alt={alt} className={`${styles.icon} ${className}`}/>
}