import React, { FunctionComponent, useCallback, useState } from 'react';
import { CouponTypeEnum, CouponUseTypeEnum, CreateCouponDto } from '../../../types/DIscountCoupon';
import { createCoupon, getCouponIsLoading } from '../../../store/couponsSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import styles from './create-coupon.module.scss';
import { Field } from '../../utils/field';
import { SelectField } from '../../utils/select-field';
import { generateOptions } from '../../../utils';
import { allCouponTypes, allCouponUseTypes, formatCouponType, formatCouponUseType } from '../../../utils/formatters';
import { FieldNumber } from '../../utils/field-number';
import { AutocompleteField } from '../../utils/autocomplete-field';
import { getClient, getClientSearcher } from '../../../store/clientSlice';
import { Button } from '../../utils/button';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';
import { HeaderApp } from '../../utils/header';
import { Card } from '../../utils/card';
import { FieldDate } from '../../utils/field-date';

const couponTypes = generateOptions(allCouponTypes);
const useTypes = generateOptions(allCouponUseTypes);

export const CreateCoupon: FunctionComponent = (props) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getCouponIsLoading);

    const navigate = useNavigate();

    const [key, setKey] = useState('');
    const [type, setType] = useState<CouponTypeEnum>(CouponTypeEnum.absolute);
    const [discountValue, setDiscountValue] = useState(0);
    const [clientId, setClientId] = useState<string>();
    const [useType, setUseType] = useState(CouponUseTypeEnum.oneUse);
    const [limitQuantity, setLimitQuantity] = useState<number>();
    const [startValidPeriod, setStartValidPeriod] = useState<Date>();
    const [finalValidPeriod, setFinalValidPeriod] = useState<Date>();
    const [minPurchaseValue, setMinPurchaseValue] = useState<number>();

    const handleAction = useCallback(
        () => {
            const payload: CreateCouponDto = {
                key,
                type,
                discountValue,
                clientId,
                useType,
                limitQuantity,
                startValidPeriod,
                finalValidPeriod,
                minPurchaseValue
            };

            dispatch(createCoupon(payload))
                .then(() => {
                    navigate('/coupons');
                });
        },
        [clientId, discountValue, dispatch, finalValidPeriod, key, limitQuantity, minPurchaseValue, navigate, startValidPeriod, type, useType]
    );

    const isDisabled =
        !key ||
        !type ||
        (!discountValue && type !== CouponTypeEnum.freeShipment) ||
        !useType;

    const client = useAppSelector(getClient(clientId));

    const breadcrumb: BreadcrumbItem[] = [
        { label: 'Cupons', link: '/coupons' },
        { label: 'Novo Cupom' }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumb} />
            <Card>
                <HeaderApp title="Criar um cupom" />
                <div className={styles.createCoupon}>
                    <Field label="Chave" placeholder="ifood-2020" onChange={setKey} value={key} />
                    <SelectField options={couponTypes} onChange={setType} value={type} />
                    {type !== CouponTypeEnum.freeShipment && (
                        <FieldNumber
                            label="Valor do desconto"
                            placeHolder="10.00"
                            onChange={setDiscountValue}
                            value={discountValue} />
                    )}
                    <AutocompleteField
                        label="Client"
                        placeholder="Jhon maclane"
                        onChange={client => setClientId(client?.id)}
                        value={client}
                        searcher={getClientSearcher}
                        getLabel={x => x.name}
                    />

                    <SelectField options={useTypes} onChange={setUseType} value={useType} />
                    <FieldNumber
                        label="Quantidade limite"
                        placeHolder="13"
                        onChange={setLimitQuantity}
                        value={limitQuantity} />
                    <FieldNumber
                        label="Valor minimo de compra"
                        placeHolder="43.30"
                        onChange={setMinPurchaseValue}
                        value={minPurchaseValue} />

                    <FieldDate
                        label="Inicio periodo de validade"
                        value={startValidPeriod}
                        onChange={setStartValidPeriod} />

                    <FieldDate
                        label="Final periodo de validade"
                        value={finalValidPeriod}
                        onChange={setFinalValidPeriod} />

                    <div className={styles.actions}>
                        <Button
                            isLoading={isLoading}
                            label="Criar cupom"
                            disabled={isDisabled}
                            onClick={handleAction} />
                    </div>
                </div>
            </Card>
        </>
    );
};
