import { FunctionComponent, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { getOrder } from '../../../store/orderSlice';
import { HeaderApp } from '../../utils/header';
import { Card } from '../../utils/card';
import styles from './order-details.module.scss';
import { Label } from '../../utils/label';
import { formatDeliveryMethod, formatPaymentInfo, formatPriceAmount, formatStatus } from '../../../utils/formatters';
import { DeliveryMethodEnum, OrderProduct, ToGoOrder } from '../../../types/Order';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';

export const OrderDetails: FunctionComponent = props => {
    const navigate = useNavigate();

    const { id } = useParams();
    const order = useAppSelector(getOrder(id));

    const renderToGoOrder = (obj: ToGoOrder) => {
        const { complement, neighborhood, number, streetLine, city, postalCode, lat, lng } = obj.address;

        return (
            <>
                <Label text="Taxa de entrega" content={formatPriceAmount(obj.area.deliveryFee)} />
                <Label text="Endereço">
                    <span>
                        {streetLine} {number} <br />
                        {!!complement && (<>{complement}<br /></>)}
                        {neighborhood} <br />
                        {city} - {postalCode} <br />
                        <a
                            target="_blank"
                            rel="noreferrer nofollow"
                            href={`https://www.google.com/maps/@${lat},${lng},13z?entry=ttu`}>
                            Mapa
                        </a>
                    </span>
                </Label>
            </>
        );
    };
    const renderDeliverInHouseInfo = () =>
        <Label text="Forma de entrega">Balcão</Label>;

    const openProduct = useCallback((product: OrderProduct) => {
        navigate(`/products/${product.product.id}/edit`);
    }, [navigate]);

    if (!order)
        return <div>Pedido não encontrado</div>;

    const DeliveryRender = {
        [DeliveryMethodEnum.delivery]: () => renderToGoOrder(order as ToGoOrder),
        [DeliveryMethodEnum.pickup]: renderDeliverInHouseInfo
    }[order.deliveryMethod];

    const breadcrumbItems: BreadcrumbItem[] = [
        { label: 'Pedidos', link: '/orders' },
        { label: `Pedido #${order.id}` }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumbItems} />
            <Card className={styles.orderDetails}>
                <HeaderApp title={`Pedido #${order.id}`} />

                <div className={styles.info}>
                    <Label text="Id" content={order.id.toString()} />
                    <Label text="Pagamento" content={formatPaymentInfo(order)} />
                    <Label text="Status" content={formatStatus(order)} />
                    <Label text="Metodo de entrega" content={formatDeliveryMethod(order)} />
                    <Label text="Cliente">
                        <Link to={`/clients/${order.client.id}`}>{order.client.name}</Link>
                    </Label>
                    <DeliveryRender />
                    {!!order.discountCoupon && (
                        <>
                            <Label text="Cupom de desconto" content={order.discountCoupon.key} />
                            <Label
                                text="Valor de desconto"
                                content={formatPriceAmount(order.discountCoupon.discountValue)} />
                        </>
                    )}
                    <Label text="Data do pedido" content={order.createdAt.toLocaleString()} />
                </div>

                <div className={styles.tableProducts}>
                    <table>
                        <thead>
                        <tr>
                            <th>Quantidade</th>
                            <th>Produto</th>
                            <th>Opção</th>
                            <th>Observação</th>
                            <th>Preço Unitario</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order.products.map(x => (
                            <tr key={x.product.id} onClick={openProduct.bind(this, x)}>
                                <td data-title="Quantidade">{x.quantity}</td>
                                <td data-title="Produto">{x.product.name}</td>
                                <td data-title="Opção">{x.option}</td>
                                <td data-title="Observação">{x.observation}</td>
                                <td data-title="Preço Unitário">{formatPriceAmount(x.product.price)}</td>
                                <td data-title="Total">{formatPriceAmount(x.quantity * x.product.price)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
};
