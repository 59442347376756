import styles from './login.module.scss';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import LogoImage from '../../assets/images/logo.png';
import { FieldPhoneNumber } from '../utils/field-phone-number';
import { Field } from '../utils/field';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Button } from '../utils/button';
import {
    create2fa,
    getIsLoginLoading,
    getLoginIsStarted,
    login,
    LoginStateEnum
} from '../../store/authSlice';
import useKeyPress from '../../hooks/useKeyPress';
import { FieldMasked } from '../utils/field-masked';

export const Login: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState('');

    const isLoading = useAppSelector(getIsLoginLoading);
    const step = useAppSelector(getLoginIsStarted);

    const phoneNumber = `55${phone}`;

    const sendTwoFa = useCallback(() => {
        dispatch(create2fa({ phoneNumber, password }));
    }, [dispatch, password, phoneNumber]);

    const sendLoginInfo = useCallback(() => {
        dispatch(login(twoFactorCode));
    }, [dispatch, twoFactorCode]);

    const btnDisabled = isLoading
        || !phone
        || !password
        || (step === LoginStateEnum.willDoTwoFactorAuth && !twoFactorCode);

    useKeyPress('Enter', () => {
        if (btnDisabled)
            return;

        const handle = {
            [LoginStateEnum.willDoBasicAuth]: sendTwoFa,
            [LoginStateEnum.willDoTwoFactorAuth]: sendLoginInfo
        }[step];

        handle();
    }, [isLoading, sendTwoFa, step]);

    return (
        <div className={styles.login}>
            <div className={styles.logoBox}>
                <img src={LogoImage} alt="logo" className={styles.logo} />

                <div className="form">
                    {step === LoginStateEnum.willDoBasicAuth && (
                        <>
                            <FieldPhoneNumber
                                disabled={isLoading}
                                value={phone}
                                onChange={setPhone} />

                            <Field
                                label="Password"
                                placeholder="Password"
                                onChange={setPassword}
                                obscureText={true}
                                disabled={isLoading} />

                            <Button label="Entrar" onClick={sendTwoFa} disabled={btnDisabled} isLoading={isLoading} />
                        </>
                    )}

                    {step === LoginStateEnum.willDoTwoFactorAuth && (
                        <>
                            <FieldMasked
                                value={twoFactorCode}
                                onChange={setTwoFactorCode}
                                label="Two Factor Code"
                                placeHolder="142312"
                                formatter={x => x}
                                validator={x => x.length === 6} />

                            <Button
                                label="Entrar"
                                onClick={sendLoginInfo}
                                disabled={btnDisabled}
                                isLoading={isLoading} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
