import { Api } from './Api';
import { Settings } from '../types/Settings';

const baseUrl = process.env.REACT_APP_SETTINGS_API!;

export default class SettingsService {
    public static update(obj: Settings): Promise<Settings> {
        return Api.put<Settings>(baseUrl, obj);
    }
}
