export enum Role {
    admin = 1,
    operator = 2,
    kitchen = 4
}

export interface BackofficeBasicUser {
    name: string;
    phoneNumber: string;
}

export interface BackofficeUser extends BackofficeBasicUser {
    id: string;
    role: Role;
}

export interface NewlyCreatedBackofficeUser extends BackofficeUser {
    password: string;
}

export interface UpdateOperatorDto extends BackofficeBasicUser {
    id: string;
}

export interface SendTwoFactorCode {
    phoneNumber: string;
    password: string;
}

export type LoginRequest = SendTwoFactorCode & {
    twoFactorCode: string
}

export interface LoginResponse {
    token: string;
    shouldChangePassword: boolean;
}

export type CreateUserDto = BackofficeBasicUser;

export interface UpdateUserDto extends CreateUserDto {
    id: string;
}
