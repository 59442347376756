import React, { FunctionComponent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteCoupon, downloadCoupons, getCoupons, getCouponsTotal } from '../../store/couponsSlice';
import { BasePagination, basicPagination } from '../../utils/pagination';
import { Paginator } from '../utils/paginator';
import { With } from '../../types';
import { DiscountCoupon } from '../../types/DIscountCoupon';
import { Card } from '../utils/card';
import { FloatingActionButton } from '../utils/floating-action-button';
import { IconButton } from '../utils/icon-button';
import { HeaderApp } from '../utils/header';
import AddIcon from '../../assets/images/icons/add.svg';

import styles from './coupons.module.scss';
import { formatCouponUseType, formatCouponValue } from '../../utils/formatters';
import { getClient } from '../../store/clientSlice';
import { DropdownItem, Dropdowns } from '../utils/dropdowns';
import EditIcon from '../../assets/images/icons/edit.svg';
import TrashIcon from '../../assets/images/icons/delete.svg';
import { openConfirmation } from '../../store/appSlice';

export const Coupons: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const lst = useAppSelector(getCoupons(pagination));
    const total = useAppSelector(getCouponsTotal);

    const downloadReport = useCallback(() => {
        dispatch(downloadCoupons());
    }, [dispatch]);

    const renderTable = () => {
        if (!total)
            return <div>Nenhum cupom encontrado</div>;

        return (
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Chave</th>
                        <th>Desconto</th>
                        <th>Cliente</th>
                        <th>Forma de uso</th>
                        <th>Quantidade</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {lst.map(x => (
                        <CouponItem key={x.id} data={x} />
                    ))}
                    </tbody>
                </table>
                <Paginator pagination={pagination} totals={total} setPage={setPagination} />
            </div>
        );
    };
    return <Card className={styles.coupons}>
        <HeaderApp title="Cupons de desconto">
            <IconButton icon="download" onClick={downloadReport} />
        </HeaderApp>
        {renderTable()}
        <FloatingActionButton icon={AddIcon} onClick={() => navigate('/coupons/new')} />
    </Card>;
};

const CouponItem: FunctionComponent<With<DiscountCoupon>> = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const openCoupon = useCallback(() =>
        navigate(`/coupons/${data.id}/edit`), [data.id, navigate]);

    const client = useAppSelector(getClient(data.clientId));

    const qtd = `${data.qtyUsed} / ${data.limitQuantity || 'Ilimitado'}`;
    return (
        <tr>
            <td data-title="Id" onClick={openCoupon}>{data.id}</td>
            <td data-title="Chave" onClick={openCoupon}>{data.key}</td>
            <td data-title="Desconto" onClick={openCoupon}>{formatCouponValue(data)}</td>
            <td data-title="Cliente" onClick={openCoupon}>{client?.name}</td>
            <td data-title="Forma de uso" onClick={openCoupon}>{formatCouponUseType(data.useType)}</td>
            <td data-title="Quantidade" onClick={openCoupon}>{qtd}</td>
            <td>
                <DropdownCoupon data={data} />
            </td>
        </tr>
    );
};

const DropdownCoupon: FunctionComponent<With<DiscountCoupon>> = ({ data }) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id } = data;

    const editCouponOption: DropdownItem = {
        icon: EditIcon,
        onClick: () => navigate(`/coupons/${id}/edit`),
        label: 'Edit'
    };

    const removeCouponOption: DropdownItem = {
        icon: TrashIcon,
        onClick: () => dispatch(openConfirmation({
            title: `Remove ${data.key}`,
            text: `Você confirma a deleção do cupom ${data.key}?`,
            onConfirm: () => dispatch(deleteCoupon(data)),
        })),
        label: 'Delete'
    };

    return <Dropdowns options={[editCouponOption, removeCouponOption]} />;
};
