import { FunctionComponent } from 'react';
import './spinLoading.scss';

export const SpinLoading: FunctionComponent = () => {
    return (
        <div className="spin-loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
