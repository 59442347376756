import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import './autocomplete-field.scss';
import { HasId } from '../../../types';
import useOnClickOutside from '../../../hooks/useOnclickOutside';
import { IconButton } from '../icon-button';
import { RootState } from '../../../store';
import { useAppSelector } from '../../../hooks';


interface Props<T extends HasId> {
    label: string;
    placeholder: string;
    value?: T;
    onChange: (val?: T) => void;
    searcher: (term: string) => (root: RootState) => T[];
    getLabel: (obj: T) => string;
    disabled?: boolean;
}


export const AutocompleteField = <T extends HasId>(props: Props<T>) => {
    const ref = useRef(null);
    const [isOpened, setIsOpened] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [lastSearchTerm, setLastSearchTerm] = useState('');

    const [chosen, setChosen] = useState<T | null>(null);

    const inputElement = useRef<HTMLInputElement>(null);

    useOnClickOutside(ref, () => {
        if (!isOpened)
            return;

        setIsOpened(false);
    });

    useEffect(() => {
        const typingTimer = setTimeout(() => {
            if (!searchTerm)
                return;

            setLastSearchTerm(searchTerm);
            setIsOpened(true);
        }, 500);

        return () => {
            clearTimeout(typingTimer);
        };
    }, [searchTerm]);

    const clearField = () => {
        setSearchTerm('');
        setChosen(null);
        setIsOpened(false);
        props.onChange();
    };

    useEffect(() => {
        if (!props.value || props.value.id === chosen?.id)
            return;

        setChosen(props.value);
    }, [chosen, props.value]);


    const handleChange = useCallback((el: ChangeEvent<HTMLInputElement>) => {
        const { value } = el.target;

        if (!!chosen && value === props.getLabel(chosen))
            return;

        setSearchTerm(value);
        setChosen(null);
        props.onChange();

        if (!value) {
            setLastSearchTerm('');
            setIsOpened(false);
        }
    }, [chosen, props]);

    const handleChosen = useCallback((data: T) => () => {
        setChosen(data);
        props.onChange(data);
        setIsOpened(false);
    }, [props]);


    const val = chosen ? props.getLabel(chosen!) : searchTerm;

    const styleOpen = isOpened ? 'open' : '';
    const styleHasValue = !!val ? 'has-value' : '';
    const styleDisabled = props.disabled ? 'disabled' : '';

    const lastSearchResult = useAppSelector(props.searcher(lastSearchTerm));

    return (
        <div className={`autocompleteField ${styleOpen} ${styleHasValue} ${styleDisabled}`}>
            <label onClick={() => inputElement.current!.focus()}>
                {props.label}
            </label>
            <input
                ref={inputElement}
                className="input"
                placeholder={props.placeholder}
                onChange={handleChange}
                disabled={props.disabled}
                value={val}
            />

            {!!val && (
                <IconButton icon="cancel" size="normal" styleWeight="slim" onClick={clearField} />
            )}

            <div className="autocompleteOptions">
                {lastSearchResult.map(data => (
                    <span key={data.id} onClick={handleChosen(data)}>{props.getLabel(data)}</span>
                ))}
            </div>
        </div>
    );
};
