import { FunctionComponent } from 'react';
import { Breadcrumb } from '../utils/breadcrumb';
import { BaseFormOperator } from './BaseFormOperator';
import { Card } from '../utils/card';
import { HeaderApp } from '../utils/header';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { createOperator } from '../../store/operatorSlice';

export const AddOperator: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleAction = (obj: any) => {
        dispatch(createOperator(obj))
            .then(() => navigate('/operators'));
    };

    const breadcrumb = [
        { label: 'Operadores', link: '/operators' },
        { label: 'Novo operador' }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumb} />
            <Card>
                <HeaderApp title="Criar um operador" />
                <BaseFormOperator labelAction="Inserir" onAction={handleAction} />
            </Card>
        </>
    );
};
