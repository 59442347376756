import { LatLngLiteral } from '@googlemaps/google-maps-services-js/dist';
import L, { HeatLayer, LatLng, Layer, LayerGroup, Map, Polygon } from 'leaflet';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Area } from "../../../types/Area";
import { RootState } from "../../../store";
import { selectArea, unselectArea, updateSelectedArea } from "../../../store/areasSlice";
import styles from './area.module.scss';

import 'leaflet-draw';
import 'leaflet.heat';

const URL_MAP_TILE = process.env.REACT_APP_MAP_URL_TILE!;

interface PropActions {
    onChangeArea: (polygon: LatLngLiteral[]) => void;
    closeEdition: () => void;
    onSelectArea: (area: Area) => void;
}

interface PropStates {
    lstAreas: Area[];
    selectedArea: Area | null;
    lstOrderLocations: LatLngLiteral[];
}

type IPropsMaps = PropActions & PropStates;

class MapArea extends Component<IPropsMaps> {
    private map: Map | undefined;
    private polygons: Polygon[] = [];
    private heatLayer!: HeatLayer;

    private drawnItems: LayerGroup | undefined;
    private drawControl: any;

    componentDidMount(): void {
        this.createMap();
        this.initializeLayersMap();
    }

    componentDidUpdate(): void {
        this.initializeLayersMap();
    }

    componentWillUnmount(): void {
        this.map!.off();
        this.map!.remove();
    }

    private initializeLayersMap = () => {
        this.createAreas();
        this.openUpEditor();
        this.createHeatMapOrders();
    };

    private createMap = () => {
        this.map = L.map('mapArea').setView([-23.6013319, -46.622806], 12);

        L.tileLayer(URL_MAP_TILE, {
            maxZoom: 18,
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(this.map);
    };

    private createAreas = () => {
        this.polygons.forEach(p => this.map?.removeLayer(p));

        if (!!this.props.selectedArea || !this.props.lstAreas.length)
            return;

        this.polygons = this.props.lstAreas.map(area => {
            const layer = new L.Polygon(area.polygon).addTo(this.map as Map);
            layer.on('click', () => {
                this.props.onSelectArea(area);
            });
            return layer;
        });

        const polygons = this.props.lstAreas.map(x => x.polygon);
        const filledPolygons = polygons.filter(x => !!x.length)
            .reduce((agg, b) => [...agg, ...b], []);

        const bounds = L.latLngBounds(filledPolygons);

        setTimeout(() => {
            this.map!.fitBounds(bounds);
        }, 0)
    };

    private createHeatMapOrders = () => {
        if (!!this.heatLayer) this.map?.removeLayer(this.heatLayer);

        if (!this.map || !this.props.lstOrderLocations) return;

        this.heatLayer = L.heatLayer(
            this.props.lstOrderLocations.map(x => [x.lat, x.lng, 50]),
            { radius: 10 }
        ).addTo(this.map);
    };

    private openUpEditor = () => {
        const clear = () => {
            if (!this.drawControl || !this.drawnItems) return;

            this.map?.removeControl(this.drawControl);
            this.map?.removeLayer(this.drawnItems as Layer);
        };

        clear();

        if (!this.map || !this.props.selectedArea) return;

        const { polygon } = this.props.selectedArea;

        if (!!polygon.length) {
            const bound = L.latLngBounds(polygon);
            this.map.fitBounds(bound);
        }

        this.drawnItems = new L.FeatureGroup([new L.Polygon(polygon)]);
        this.map.addLayer(this.drawnItems);
        this.drawControl = new L.Control.Draw({
            draw: {
                polygon: false,
                circle: false,
                marker: false,
                circlemarker: false,
                rectangle: false,
            },
            edit: {
                featureGroup: this.drawnItems as any,
            }
        });
        this.map.addControl(this.drawControl);

        const updatePolygon = (polygon: LatLng[]) => {
            this.props.onChangeArea(polygon);
        };

        this.map.on(L.Draw.Event.CREATED, e => {
            const layer = e.layer;

            this.drawnItems?.clearLayers();
            this.drawnItems?.addLayer(layer);

            const polygon = layer.editing.latlngs[0];
            updatePolygon(polygon);
        });

        this.map.on(L.Draw.Event.EDITED, (e: any) =>
            e.layers.eachLayer((layer: any) => {
                const polygon = layer.editing.latlngs[0][0] as LatLng[];
                updatePolygon(polygon);
            })
        );
    };

    render = () => (
        <div className={styles.area}>
            <div className={styles.mapArea} id='mapArea'>
                {!!this.props.selectedArea && (
                    <a onClick={this.props.closeEdition} className={styles.closeButton}>x</a>
                )}
            </div>
        </div>
    );
}

function mapStateToProps(state: RootState): PropStates {
    const { lstAreas, selectedArea, lstOrderLocations } = state.area;
    return { lstAreas, selectedArea, lstOrderLocations };
}

const mapDispatchToProps = (dispatch: any): PropActions => ({
    onChangeArea: (polygon: LatLngLiteral[]) => dispatch(updateSelectedArea({ polygon })),
    closeEdition: () => dispatch(unselectArea()),
    onSelectArea: (area: Area) => dispatch(selectArea(area))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapArea);
