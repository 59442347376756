import { Area } from "../types/Area";
import { Api } from "./Api";
import { getUrlGenerator } from "../utils";

const baseUrl = process.env.REACT_APP_LOCATION_API;

const generateUrl = getUrlGenerator(baseUrl);
export class AreaService{
    public static updateAreas = (areas: Area[]): Promise<Area[]> =>
        Api.put(generateUrl('area'), areas);
}