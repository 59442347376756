import { LatLngLiteral } from '@googlemaps/google-maps-services-js/dist';
import { Area } from '../types/Area';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, retrieveFromLocalStorage, saveOnLocalStorage, upsertOnList } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { AreaService } from '../services/AreaService';
import { showAlert } from './notificationSlice';
import { Websocket } from '../services/Websocket';
import { removeOnLocalStorage } from '../utils/storage';

const AREAS_KEY = 'areas';

interface AreasState {
    lstAreas: Area[];
    selectedArea: Area | null;
    lstOrderLocations: LatLngLiteral[];
    someAreaWasEdited: boolean;
}

const initialState: AreasState = {
    lstAreas: [],
    selectedArea: null,
    lstOrderLocations: [],
    someAreaWasEdited: false
};

const areaSlice = createSlice({
    name: 'area',
    initialState,
    reducers: {
        updateListAreas: (state, action: PayloadAction<Area[]>) => {
            state.lstAreas = action.payload;
            state.selectedArea = null;
            state.someAreaWasEdited = false;
        },
        selectArea: (state, action: PayloadAction<Area>) => {
            state.selectedArea = action.payload;
        },
        unselectArea: state => {
            state.selectedArea = null;
        },
        addNewArea: state => {
            const fakeId = Math.max(...state.lstAreas.map(x => Number(x.id)), 0) + 1;
            const obj: Area = { id: fakeId.toString(), name: '', deliveryFee: 0, polygon: [] };
            state.lstAreas = [...state.lstAreas, obj];
            state.selectedArea = obj;
        },
        updateSelectedArea: (state, action: PayloadAction<Partial<Area>>) => {
            const newArea: Area = { ...state.selectedArea!, ...action.payload };
            state.lstAreas = upsertOnList(state.lstAreas, newArea);
            state.selectedArea = newArea;
            state.someAreaWasEdited = true;
        },
        removeArea: (state, action: PayloadAction<string>) => {
            state.lstAreas = state.lstAreas.filter(x => x.id !== action.payload);
            state.someAreaWasEdited = true;
        }
    }
});

export const {
    updateListAreas,
    selectArea,
    unselectArea,
    addNewArea,
    updateSelectedArea,
    removeArea
} = areaSlice.actions;

export const resetChanges = (): AppThunk => dispatch => loadFromStorage(dispatch);

export const updateAreas = (): AppThunk => (dispatch, getState) => {
    const areas = getState().area.lstAreas;
    AreaService.updateAreas(areas)
        .then(() => {
            dispatch(showAlert('As Areas foram atualizadas'));
        });
};

export const cleanAreasStateAndStorage = (): AppThunk => (dispatch, getState) => {
    dispatch(updateListAreas([]));
    removeOnLocalStorage(AREAS_KEY);
};

export const setupAreasState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Area[]>('updated-areas', lst => {
        console.log('cheguei nas areas');
        console.log(lst)
        dispatch(updateListAreas(lst));
        return updateOnStorage(getState);
    });
};

export const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().area.lstAreas;
    saveOnLocalStorage(AREAS_KEY, lst);
};

export const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Area[]>(AREAS_KEY);

    if (!lst)
        return;

    dispatch(updateListAreas(lst));
};


export const getSelectedArea = (root: RootState): Area | null => root.area.selectedArea;
export const getAreas = (root: RootState): Area[] => root.area.lstAreas;
export const getSomeAreaWasEdited = (root: RootState): boolean => root.area.someAreaWasEdited;

export default areaSlice.reducer;
