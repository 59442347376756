import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getClient, getClientIsLoading, updateClient } from '../../../store/clientSlice';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Card } from '../../utils/card';
import { HeaderApp } from '../../utils/header';
import { Field } from '../../utils/field';
import { UpdateClientDto } from '../../../types/Client';
import { FieldPhoneNumber } from '../../utils/field-phone-number';
import { Switch } from '../../utils/switch';
import { Button } from '../../utils/button';
import styles from './edit-client.module.scss';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';

export const EditClient: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const { id } = useParams<{ id: string }>();
    const client = useAppSelector(getClient(id));

    const isLoading = useAppSelector(getClientIsLoading);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [receiveEmails, setReceiveEmails] = useState(false);

    useEffect(() => {
        if (!client)
            return;

        setName(client.name);
        setEmail(client.email);
        setPhone(client.phoneNumber.slice(-11));
        setReceiveEmails(client.receiveEmails);
    }, [client]);

    const handleBtnClick = useCallback(() => {
        const payload: UpdateClientDto = {
            id: id!,
            name,
            email,
            phoneNumber: `55${phone}`,
            receiveEmails
        };

        dispatch(updateClient(payload));
    }, [dispatch, email, id, name, phone, receiveEmails]);

    const didChange =
        name !== client?.name ||
        email !== client?.email ||
        phone !== client?.phoneNumber.slice(-11) ||
        receiveEmails !== client?.receiveEmails;

    const btnDisabled = !didChange || isLoading;

    if (!client)
        return <div>Cliente não encontrado</div>;

    const breadcrumbs: BreadcrumbItem[] = [
        { label: 'Clientes', link: '/clients' },
        { label: client.name, link: `/clients/${client.id}` },
        { label: 'Editar' }
    ];

    return (
        <>
            <Breadcrumb data={breadcrumbs} />
            <Card className={styles.editClient}>
                <HeaderApp title={`Editar cliente ${client.name}`} />

                <div className={styles.form}>
                    <Field
                        label="Nome"
                        placeholder="Jhon Doe"
                        onChange={setName}
                        value={name} />

                    <FieldPhoneNumber value={phone} onChange={setPhone} />
                    <Field
                        label="Email"
                        placeholder="jhondoe@gmail.com"
                        onChange={setEmail}
                        value={email} />

                    <Switch onChange={setReceiveEmails} value={receiveEmails}>Recebe emails</Switch>

                    <div className={styles.actions}>
                        <Button disabled={btnDisabled} isLoading={isLoading} onClick={handleBtnClick} label="Salvar" />
                    </div>
                </div>
            </Card>
        </>
    );
};
