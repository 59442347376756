import styles from './kitchen-order-card.module.scss';
import { FunctionComponent } from 'react';
import { With } from '../../../types';
import { Order, OrderProduct as OrderProductDto, StatusEnum } from '../../../types/Order';

const OrderProduct: FunctionComponent<With<OrderProductDto>> = ({ data }) => {
    return (
        <div className={styles.orderProduct}>
            <span>{data.quantity}</span> x <span>{data.product.name}</span>
            {!!data.option && (<span>({data.option})</span>)}
            {!!data.observation && <span className={styles.observation}>{data.observation}</span>}
        </div>
    );
};

export const KitchenOrderCard: FunctionComponent<With<Order>> = ({ data }) => {

    if (data.status !== StatusEnum.inPreparation)
        return null

    return (
        <div className={styles.kitchenOrderCard}>
            <div className={styles.details}>
                <h4>{data.client.name.split(' ')[0]} - {data.createdAt.toLocaleTimeString().substring(0, 5)}</h4>
                {data.products.map(product => <OrderProduct data={product} key={product.product.id}/>)}
            </div>
            <div className={styles.orderId}>{data.id.slice(-6)}</div>
        </div>
    );
};
