export enum CategoryEnum {
    entries = 'entries',
    sideDishes = 'side-dishes',
    smokedMeets = 'smoked-meets',
    grilled = 'grilled',
    burgers = 'burgers',
    sandwiches = 'sandwiches',
    beverages = 'beverages',
    sauces = 'sauces',
    salads = 'salads',
    deserts = 'deserts'
}

export enum AnnotationEnum {
    spicy = 'spicy',
    smoked = 'smoked',
    lactose = 'lactose',
}


export interface Product {
    id: string;
    name: string;
    description: string;
    category: CategoryEnum;
    price: number;
    idImage: string;
    isEnabled: boolean;
    quantity?: string;
    options?: string[];
    sortIndex: number;
    annotations: AnnotationEnum[];
    createdAt: Date;
    updatedAt: Date;
}


export interface BaseProductDto {
    name: string;
    description: string;
    category: CategoryEnum;
    isEnabled: boolean;
    annotations: AnnotationEnum[];
    quantity?: string;
    options?: string[];
    price: number;
    idImage?: string;
    newImageBase64?: string;
}

export interface CreateProductDto extends BaseProductDto {
    newImageBase64: string;
}

export interface UpdateProductDto extends BaseProductDto {
    id: string;
    idImage?: string;
    newImageBase64?: string;
}
