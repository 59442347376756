import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FieldMasked } from '../field-masked';
import { extractNumber } from '../../../utils';

interface Props {
    value?: string;
    onChange: (val: string) => void;
    label: string;
    disabled?: boolean;
}

const validTime = (val: string): boolean => {
    if (val.length !== 8)
        return false;

    const arr = val.split(':');
    if (arr.length !== 3)
        return false;

    const [hour, minute, second] = arr.map(x => Number(x));

    return !(hour > 23 || hour < 0 || minute > 59 || minute < 0 || second > 59 || second < 0);
};

export const timeInputFormatter = (time: string | null | undefined): string => {
    if (!time) return '';
    const num = extractNumber(time);

    //235959
    const slice = (start: number, end: number): string => num.slice(start, end);
    const firstBloc = slice(0, 2);
    const secondBloc = slice(2, 4);
    const thirdBloc = slice(4, 6);

    //23:59:59

    if (!!thirdBloc.length)
        return `${firstBloc}:${secondBloc}:${thirdBloc}`;

    if (!!secondBloc.length)
        return `${firstBloc}:${secondBloc}`;

    return firstBloc;
};


export const FieldTime: FunctionComponent<Props> = props => {
    const [val, setVal] = useState('');

    const handleChange = useCallback((value: string) => {
        setVal(value);
        props.onChange(timeInputFormatter(value));
    }, [setVal, props]);

    useEffect(() => {
        if (extractNumber(val) === props.value)
            return;

        setVal(props.value?.toString() || '');
    }, [setVal, props, val]);

    return (
        <FieldMasked
            {...props}
            value={val}
            onChange={handleChange}
            placeHolder="23:59:59"
            formatter={timeInputFormatter}
            validator={validTime} />
    );
};
