import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { Client, UpdateClientDto } from '../types/Client';
import { Websocket } from '../services/Websocket';
import { Columns, downloadCsv } from '../utils/report';
import { BasePagination, getPaged } from '../utils/pagination';
import { showAlert } from './notificationSlice';
import ClientService from '../services/ClientService';

const CLIENTS_KEY = 'clients';
const CLIENTS_LAST_UPDATED_DATE = 'clients_last_updated';

interface ClientState {
    lstClients: Client[];
    isLoading: boolean;
}

const initialState: ClientState = {
    lstClients: [],
    isLoading: false
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        upsertClient: (state, action: PayloadAction<Client>) => {
            state.lstClients = upsertOnList(state.lstClients, action.payload);
        },
        removeClient: (state, action: PayloadAction<string>) => {
            state.lstClients = state.lstClients.filter(x => x.id !== action.payload);
        },
        updateClientList: (state, action: PayloadAction<Client[]>) => {
            state.lstClients = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});

const { upsertClient, setIsLoading, removeClient, updateClientList } = clientSlice.actions;

export const updateClient = (obj: UpdateClientDto): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));

    ClientService.update(obj).then(x => {
        console.log('Client updated');
        dispatch(showAlert('Cliente atualizado com sucesso'));
    }).finally(() => {
        dispatch(setIsLoading(false));
    });
};

export const downloadClientsList = (): AppThunk => async (dispatch, getState) => {
    const lst = getState().client.lstClients;

    const biggestNumberOfAddresses = lst.reduce((acc, x) => Math.max(acc, x.addresses.length), 0);
    const biggestNumberOfCreditCards = lst.reduce((acc, x) => Math.max(acc, x.paymentCards.length), 0);

    const getAddressColumns = (index: number): Columns<Client> => [
        { title: `${index + 1} Endereço Nome`, getValue: x => x.addresses[index]?.description },
        { title: `${index + 1} Endereço é o principal`, getValue: x => x.addresses[index]?.isDefault ? 'Sim' : 'Não' },
        { title: `${index + 1} Endereço logradouro`, getValue: x => x.addresses[index]?.info.streetLine },
        { title: `${index + 1} Endereço número`, getValue: x => x.addresses[index]?.info.number },
        { title: `${index + 1} Endereço bairro`, getValue: x => x.addresses[index]?.info.neighborhood },
        { title: `${index + 1} Endereço complemento`, getValue: x => x.addresses[index]?.info.complement },
        { title: `${index + 1} Endereço cidade`, getValue: x => x.addresses[index]?.info.city },
        { title: `${index + 1} Endereço CEP`, getValue: x => x.addresses[index]?.info.postalCode },
    ];

    const getPaymentCardColumns = (index: number): Columns<Client> => [
        { title: `${index + 1} Dono do Cartão de crédito`, getValue: x => x.paymentCards[index]?.name },
        { title: `${index + 1} Documento do dono Cartão de crédito`, getValue: x => x.paymentCards[index]?.document },
        { title: `${index + 1} Cartão de crédito número`, getValue: x => x.paymentCards[index]?.number },
        { title: `${index + 1} Cartão de crédito CCV`, getValue: x => x.paymentCards[index]?.ccv },
        { title: `${index + 1} Cartão de crédito validade`, getValue: x => x.paymentCards[index]?.expiration },
        { title: `${index + 1} Cartão de crédito bandeira`, getValue: x => x.paymentCards[index]?.brand },
        {
            title: `${index + 1} Cartão de crédito criado em`,
            getValue: x => x.paymentCards[index]?.createdAt.toLocaleString()
        },
    ];

    const addressesColumns = Array.from({ length: biggestNumberOfAddresses }, (_, i) => getAddressColumns(i)).flat();
    const cardColumns = Array.from({ length: biggestNumberOfCreditCards }, (_, i) => getPaymentCardColumns(i)).flat();

    const columns: Columns<Client> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Nome', getValue: x => x.name },
        { title: 'Telefone', getValue: x => x.phoneNumber },
        { title: 'Email', getValue: x => x.email },
        { title: 'Recebe emails', getValue: x => String(x.receiveEmails) },
        { title: 'Criado em', getValue: x => x.createdAt.toLocaleString() },
        { title: 'Atualizado em', getValue: x => x.updatedAt.toLocaleString() },
        { title: 'Quantidade de endereços', getValue: x => x.addresses.length.toString() },
        { title: 'Quantidade de cartões de crédito', getValue: x => x.paymentCards.length.toString() },
        ...addressesColumns,
        ...cardColumns,
    ];

    downloadCsv(lst, columns, 'clients.csv');
};

export const cleanClientStateAndStorage = (): AppThunk => async (dispatch) => {
    dispatch(updateClientList([]));
    removeOnLocalStorage(CLIENTS_KEY);
    removeOnLocalStorage(CLIENTS_LAST_UPDATED_DATE);
};

export const setupClientState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Client>('update-client', client => {
        dispatch(upsertClient(client));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(CLIENTS_LAST_UPDATED_DATE);
    Websocket.emitOnConnection('subscribe-clients', payload);
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().client.lstClients;
    saveOnLocalStorage(CLIENTS_KEY, lst);
    saveLastUpdatedDate(CLIENTS_LAST_UPDATED_DATE, lst);
};

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const lst = retrieveFromLocalStorage<Client[]>(CLIENTS_KEY);

    if (!lst)
        return;

    dispatch(updateClientList(lst));
};

export const getClients = (pagination: BasePagination) => (root: RootState) => {
    const lst = root.client.lstClients;
    return getPaged(pagination, lst);
};

export const getTotalClients = (root: RootState): number => root.client.lstClients.length;

export const getClient = (id: string | undefined) => (root: RootState): Client | undefined =>
    root.client.lstClients.find(x => x.id === id);

export const getClientIsLoading = (root: RootState): boolean => root.client.isLoading;
export const getClientSearcher = (term?: string) => (root: RootState): Client[] => {
    if (!term)
        return root.client.lstClients.slice(0, 3);

    const lowerTerm = term.toLowerCase();

    const resultsById = root.client.lstClients.filter(x => x.id === term);
    if (resultsById.length > 0)
        return resultsById;

    const resultsByName = root.client.lstClients.filter(x => x.name.toLowerCase() === lowerTerm);
    if (resultsByName.length > 0)
        return resultsByName;

    const resultsByPhoneNumber = root.client.lstClients
        .filter(x => x.phoneNumber.toLowerCase().includes(lowerTerm));
    if (resultsByPhoneNumber.length > 0)
        return resultsByPhoneNumber;

    const resultsByEmail = root.client.lstClients
        .filter(x => x.email.toLowerCase().includes(lowerTerm));
    if (resultsByEmail.length > 0)
        return resultsByEmail;

    return root.client.lstClients
        .filter(x => x.name.toLowerCase().includes(lowerTerm));
};

export default clientSlice.reducer;
